import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import MascotDashboardButton from "components/buttons/mascot-dashboard-button/MascotDashboardButton";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import { ReactComponent as IconReservation } from "assets/icons/mascot/MascotReservation.svg";
import { STORE_NAMES } from "utils/constants/redux";
import GuestAccountInfoModal from "components/guest-account-info-modal/GuestAccountInfoModal";
import GuestProfileWithIcon from "components/elements/guest-profile-with-icon/GuestProfileWithIcon";
import EditButton, {
  ENUMS as ENUMS_EDIT_BUTTON,
} from "components/admin/buttons/edit-button/EditButton";
import useOutsideClick from "utils/hooks/useOutsideClick";
import BusinessesLanguage from "pages/client/all-businesses/all-businesses-hamburger-menu/businesses-language/BusinessesLanguage";
import { getBrandName, getBrandMainLogoLight } from "utils/branding-helper";
import { resetAllReduxStores } from "utils/general";
import { setGuestInfo } from "redux/slices/guestStore";
import { setBusinessIdAndQrId } from "redux/slices/qrScanStore";
import { getGuestMetaDataAsync } from "redux/actions/metaDataAction";
import { setShowAccountInfoModal } from "redux/slices/userStore";
import GuestBlock from "components/guest/guest-block/GuestBlock";

import "./AllBusinessesHamburgerMenu.scss";

const brandName = getBrandName();
const MainLogoLight = getBrandMainLogoLight();

const AllBusinessesHamburgerMenu = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openLanguages, setOpenLanguages, mainElementRef] = useOutsideClick();
  const [openSlideGuestAccountInfoModal, setOpenSlideGuestAccountInfoModal] =
    useOutsideClick();
  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const guest = useSelector((state) => state[STORE_NAMES.guest]);
  const { table } = useSelector((state) => state[STORE_NAMES.qrScan]);

  const allLanguages = useSelector((state) => state[STORE_NAMES.app].languages);
  const showLanguagesModal = allLanguages?.length > 1 || false;
  const isTableEmpty = Object.keys(table).length === 0;

  let [searchParams, setSearchParams] = useSearchParams();

  const showHamburgerMenu = Boolean(
    searchParams.get(QUERY_PARAMS.showAllBusinessesHamburgerMenu)
  );

  useEffect(() => {
    if (showHamburgerMenu) return document.body.classList.add("OverflowHidden");
    document.body.classList.remove("OverflowHidden");
  }, [showHamburgerMenu]);

  const handleCloseHamburgerMenu = () => {
    searchParams.delete(QUERY_PARAMS.showAllBusinessesHamburgerMenu);
    setSearchParams(searchParams);
  };

  const selectedLanguage = useSelector(
    (state) => state[STORE_NAMES.user].userLanguage
  );

  const currentLanguage =
    selectedLanguage || allLanguages?.find((lang) => lang.code === "EN");

  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );
  const guestId = guest.id;

  const handleReservationButtonClick = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.reservation}${ROUTE_NAME.myReservations}`
    );
  };

  const showGuestName = () => {
    if (guest.name) {
      return guest.name;
    } else if (guestId) {
      return t("dashboard.guest.guest") + " " + guestId;
    } else {
      return t("dashboard.guest.guest");
    }
  };

  if (!showHamburgerMenu) {
    return;
  }

  const fetchGuestMetadata = async () => {
    const parsedBusinessId = parseInt(businessId, 10);
    const qrData = {
      businessId: parsedBusinessId,
      qrId: undefined,
    };

    if (businessId) {
      dispatch(setBusinessIdAndQrId(qrData));
      return dispatch(
        getGuestMetaDataAsync({
          ...qrData,
          onSuccess: async () => {
            dispatch(
              setGuestInfo({
                id: guestId,
                name: guest.name,
                phoneNumber: guest.phoneNumber,
                profilePicture: guest.profilePicture,
              })
            );
          },
        })
      );
    }
  };

  const handleFixProblem = async () => {
    navigate("/");
    await resetAllReduxStores(dispatch);
    await fetchGuestMetadata();
    navigate(`${ROUTE_NAME.client}${ROUTE_NAME.allBusinesses}`);
  };

  const showEditGuestProfileForm = () => {
    setOpenSlideGuestAccountInfoModal(true);
    dispatch(setShowAccountInfoModal(false));
  };

  if (business?.isArchived) {
    return (
      <div>
        <GuestBlock business={business} />
      </div>
    );
  }

  return (
    <>
      <div className="AllBusinessesHamburgerMenu">
        <header className="AllBusinessesHamburgerMenuHeader">
          <div className="AllBusinessesHamburgerMenuLeftArea">
            <div
              className="AllBusinessesHamburgerMenuGuestWrapper"
              onClick={
                !isTableEmpty
                  ? () => setOpenSlideGuestAccountInfoModal(true)
                  : null
              }
            >
              <GuestProfileWithIcon
                image={guest.profilePicture}
                hasImage={!!guest.profilePicture}
                className="AllBusinessesHamburgerMenuGuestImageContainer"
                actionButton={
                  !isTableEmpty && (
                    <EditButton
                      className="AllBusinessesHamburgerMenuGuestEditButton"
                      type={ENUMS_EDIT_BUTTON.types.TYPE_B}
                      onClick={showEditGuestProfileForm}
                    />
                  )
                }
              />
            </div>
            <div className="AllBusinessesHamburgerMenuGuestDetailsContainer">
              <h4 className="SemiBold AllBusinessesHamburgerMenuGuestName">
                {showGuestName()}
              </h4>
            </div>
          </div>
          <div
            className="CloseAllBusinessesHamburgerMenu"
            onClick={handleCloseHamburgerMenu}
          >
            <IconClose />
          </div>
        </header>
        <div className="AllBusinessesHamburgerMenuMain">
          <div className="AllBusinessesHamburgerMenuMainTop">
            <div className="AllBusinessesHamburgerMenuSections">
              <div>
                <MascotDashboardButton
                  isActive={false}
                  imgSrc={currentLanguage?.imgSrc}
                  title={currentLanguage?.code}
                  subTitle={t("language.language")}
                  className="CustomDashboardButton"
                  onClick={() => setOpenLanguages(showLanguagesModal)}
                  arrow={showLanguagesModal}
                />
              </div>
            </div>
            <div className={"AllBusinessesHamburgerMenuSections"}>
              <div>
                <MascotDashboardButton
                  isActive={false}
                  icon={<IconReservation />}
                  title={t("navbarRoutes.reservation")}
                  // subTitle={t("mascot.dashboard.reserveTable")}
                  onClick={handleReservationButtonClick}
                />
              </div>
            </div>
            <div className="AllBusinessesHamburgerMenuFixProblem">
              <p className="h7 Medium">
                {t("mascot.dashboard.fixProblemText")}
              </p>
              <button
                className="AllBusinessesHamburgerMenuFixProblemButton"
                onClick={handleFixProblem}
              >
                <h6>{t("mascot.dashboard.fixProblemButton")}</h6>
              </button>
            </div>
            <div className="AllBusinessesHamburgerMenuCopyRight">
              <div className="AllBusinessesHamburgerMenuLogoContainer">
                <MainLogoLight />
                <h4 className="SemiBold">{brandName}</h4>
              </div>
              <div className="h7 Medium">
                {t("general.copyRight", { value: brandName })}
              </div>
            </div>
          </div>
        </div>
        <BusinessesLanguage
          onClose={() => setOpenLanguages(false)}
          openSlide={openLanguages}
          mainElementRef={mainElementRef}
        />
        <GuestAccountInfoModal
          openSlide={openSlideGuestAccountInfoModal}
          setOpenSlide={setOpenSlideGuestAccountInfoModal}
          onClose={() => setOpenSlideGuestAccountInfoModal(false)}
          canEditGuestProfile
          guestInfo={
            guestId
              ? {
                  name: guest.name,
                  phoneNumber: guest.phoneNumber,
                  profilePicture: guest.profilePicture,
                }
              : {}
          }
        />
      </div>
      <div className="AllBusinessesHamburgerMenuUnderlay"></div>
    </>
  );
};
export default AllBusinessesHamburgerMenu;
