import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { createDOBucketName } from "utils/DO-Spaces";
import { ROUTE_NAME } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import AddItem from "./add-item/AddItem";
import { ReactComponent as CheckIcon } from "assets/icons/check/Select.svg";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import useLanguage from "utils/hooks/useLanguage";

import "./DashboardOrders.scss";

export const ENUMS = {
  name: "DashboardOrders",
};

const DashboardOrders = ({
  guests,
  setSelectedGuests,
  selectedGuests,
  selectedOrders,
  setSelectedOrders,
}) => {
  const orders = guests?.flatMap((guest) => guest.orderItems);
  const { displayDataByLanguage } = useLanguage();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const business = useSelector((state) => state[STORE_NAMES.business].business);

  useEffect(() => {
    for (const guest of guests) {
      if (guest.orderItems.length > 0) {
        const filteredItemsByIsConfirmed = guest.orderItems.filter(
          (orderItem) => orderItem
        );
        const guestAllItemsIsSelected =
          filteredItemsByIsConfirmed.length > 0 &&
          filteredItemsByIsConfirmed.every((orderItem) =>
            selectedOrders.includes(orderItem.id)
          );
        if (
          guestAllItemsIsSelected &&
          !selectedGuests.includes(guest.person.id)
        ) {
          setSelectedGuests((prev) => [...prev, guest.person.id]);
        } else if (
          !guestAllItemsIsSelected &&
          selectedGuests.includes(guest.person.id)
        ) {
          setSelectedGuests((prev) =>
            prev.filter((user) => user !== guest.person.id)
          );
        }
      }
    }
  }, [selectedOrders]);

  const handleAddItemClick = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business?.id}${ROUTE_NAME.menu}`
    );
  };

  const handleUnSelectItems = () => {
    setSelectedOrders([]);
    setSelectedGuests([]);
  };

  const handleSelectOrders = (order) => {
    setSelectedOrders((prev) => {
      if (selectedOrders.includes(order.id)) {
        return prev.filter((el) => el !== order.id);
      } else {
        return [...prev, order.id];
      }
    });
  };
  const sortedOrders = orders.sort((a, b) => {
    if (a.isConfirmed === null && b.isConfirmed !== null) {
      return -1;
    }
    if (a.isConfirmed !== null && b.isConfirmed === null) {
      return 1;
    }
    if (a.isConfirmed === b.isConfirmed) {
      return a.isArchived === b.isArchived ? 0 : a.isArchived ? 1 : -1;
    }
    return a.isConfirmed ? -1 : 1;
  });
  return (
    <div className="DashboardOrdersContainer">
      {selectedOrders.length > 0 && (
        <button className="UnSelectBtn" onClick={handleUnSelectItems}>
          {t("dashboard.unSelect")}
        </button>
      )}
      <div className="DashboardOrders">
        <AddItem handleAddItemClick={handleAddItemClick} />

        {sortedOrders.map((order, index) => (
          <div
            key={`order_item_${index}`}
            className={cx("OrderItemCard", "OrderCard", {
              selected: selectedOrders.includes(order.id),
              denied: order.isConfirmed === false && order.isArchived === false,
              deleted: order.isConfirmed === false && order.isArchived === true,
            })}
            onClick={() => handleSelectOrders(order)}
          >
            <ImageWithPlaceholder
              imageSource={createDOBucketName(order.item.coverImageSrc)}
              placeholder={IMAGE_ITEM_PLACEHOLDER}
              alt={order.item?.[0]?.name}
            />
            <span
              className={`ConfirmInfo ${
                order.isConfirmed === false && order.isArchived === false
                  ? "other"
                  : ""
              }`}
            >
              {t("dashboard.denied")}
            </span>
            <span
              className={`ConfirmInfo ${
                order.isConfirmed === false && order.isArchived === true
                  ? "other"
                  : ""
              }`}
            >
              {t("dashboard.deleted")}
            </span>

            {order.isConfirmed !== false && (
              <div
                className={cx("OrderItemStatus", {
                  isWaiting: order.isConfirmed === null,
                  isConfirmed: order.isConfirmed === true,
                })}
              ></div>
            )}
            <div className="OrderItemName">
              <h6 className="h8">{order.count}x</h6>
              <h6 className="SemiBold h7">
                {displayDataByLanguage(order.item.name)}
              </h6>
            </div>

            {selectedOrders.length > 0 && order.isConfirmed !== false && (
              <div
                className={`OrderItemSelect ${
                  selectedOrders.includes(order.id) ? "isSelected" : ""
                }`}
              >
                {selectedOrders.includes(order.id) && <CheckIcon />}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

DashboardOrders.propTypes = {
  guests: PropTypes.array,
  setSelectedGuests: PropTypes.func,
  setSelectedOrders: PropTypes.func,
  selectedGuests: PropTypes.array,
  selectedOrders: PropTypes.array,
};

export default DashboardOrders;
