export const getLevenshteinDistance = (a, b) => {
  const dist = [];
  const alen = a.length;
  const blen = b.length;

  for (let i = 0; i <= alen; i++) {
    dist[i] = [i];
  }
  for (let j = 0; j <= blen; j++) {
    dist[0][j] = j;
  }

  for (let i = 1; i <= alen; i++) {
    for (let j = 1; j <= blen; j++) {
      const cost = a[i - 1] === b[j - 1] ? 0 : 1;
      dist[i][j] = Math.min(
        dist[i - 1][j] + 1, // deletion
        dist[i][j - 1] + 1, // insertion
        dist[i - 1][j - 1] + cost // substitution
      );
    }
  }
  return dist[alen][blen];
};

export const generateLevenshteinDistance = (character) => {
  let resultDistance;
  const lengthOfCharacter = character.length;

  switch (true) {
    case lengthOfCharacter < 3:
      resultDistance = 0;
      break;
    case lengthOfCharacter >= 3 && lengthOfCharacter < 5:
      resultDistance = 1;
      break;
    case lengthOfCharacter >= 5 && lengthOfCharacter <= 7:
      resultDistance = 2;
      break;
    case lengthOfCharacter >= 8:
      resultDistance = 3;
      break;
    default:
      resultDistance = 0;
  }

  return resultDistance;
};

export const advanceSearch = ({ string, searchString }) => {
  const searchValue = searchString.trim().toLowerCase();
  const editedString = string.trim().toLowerCase();
  const splitString = editedString.split(" ");
  if (editedString.includes(searchValue)) {
    return true;
  } else if (splitString.length === 1) {
    return (
      getLevenshteinDistance(splitString[0], searchValue) <=
      generateLevenshteinDistance(splitString[0])
    );
  } else if (splitString.length > 1) {
    return splitString.some((word) => {
      return (
        getLevenshteinDistance(word, searchValue) <=
        generateLevenshteinDistance(word)
      );
    });
  } else {
    return false;
  }
};
