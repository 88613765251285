import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import cx from "classnames";
import PropTypes from "prop-types";

import Search, { ENUMS as SEARCH_ENUMS } from "components/forms/search/Search";
import InstaStories from "components/insta-stories/InstaStories";
import { ReactComponent as FilterSearchIcon } from "assets/icons/all-business/SearchInput.svg";
import { ReactComponent as Hamburger } from "assets/icons/all-business/Hamburger.svg";
import { ReactComponent as ArrowLeftWhite } from "assets/icons/arrows/ArrowLeftWhite.svg";
import { QUERY_PARAMS } from "utils/constants/routes";
import { getBrandMainLogoLight } from "utils/branding-helper";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import { createDOBucketName } from "utils/DO-Spaces";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";

import "./AllBusinessesHeader.scss";
import { useTranslation } from "react-i18next";

const AllBusinessesHeader = ({
  businesses,
  seeAll,
  setSeeAll,
  searchBusinesses,
  setSearchBusinesses,
  handleSearchBusinesses,
}) => {
  const { t } = useTranslation();
  const MainLogoLight = getBrandMainLogoLight();
  const [openSearch, setOpenSearch] = useState(false);
  const [showInstagramStory, setShowInstagramStory] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [businessesWithStatus, setBusinessesWithStatus] = useState([]);
  useEffect(() => {
    if (businesses) {
      const businessesWithShowStatus = businesses.map((business) => ({
        ...business,
        isShowStatus: false,
      }));
      setBusinessesWithStatus(businessesWithShowStatus);
    }
  }, [businesses]);

  const [showInstagramStoryId, setShowInstagramStoryId] = useState(null);

  const handleInstaStoryClick = (id, index) => {
    setBusinessesWithStatus((prevBusinesses) =>
      prevBusinesses.map((business, i) =>
        i === index ? { ...business, isShowStatus: true } : business
      )
    );
    setShowInstagramStoryId(id);

    setShowInstagramStory(true);
  };
  const handleBurgerMenuClick = () => {
    setSearchParams({
      ...searchParams,
      [QUERY_PARAMS.showAllBusinessesHamburgerMenu]: true,
    });
  };

  const openFilterSearch = () => {
    setOpenSearch(true);
  };
  const closeFilterSearch = () => {
    setOpenSearch(false);
    setSearchBusinesses("");
    setSeeAll(false);
  };
  return (
    <div className="AllBusinessesHeader">
      <div
        className="AllBusinessesHeaderTop"
        style={{
          gap: openSearch ? "12px" : "0px",
        }}
      >
        <div className="AllBusinessesHeaderTopFilter">
          {openSearch || seeAll ? (
            <ArrowLeftWhite onClick={closeFilterSearch} />
          ) : (
            <FilterSearchIcon onClick={openFilterSearch} />
          )}
        </div>
        {openSearch ? (
          <Search
            placeholder={t("businesses.search")}
            onChange={(value) => {
              handleSearchBusinesses(value);
            }}
            value={searchBusinesses}
            type={SEARCH_ENUMS.types.TYPE_C}
          />
        ) : (
          <MainLogoLight />
        )}
        <div className="AllBusinessesHeaderTopHamburgerMenu">
          <Hamburger onClick={handleBurgerMenuClick} />
        </div>
      </div>
      <div className="AllBusinessesHeaderInstaStories">
        {businessesWithStatus &&
          businessesWithStatus
            ?.filter((business) => business?.images?.other?.length > 0)
            .map((business, index) => (
              <React.Fragment key={index}>
                <div
                  className="AllBusinessesHeaderInstaStory"
                  onClick={() => handleInstaStoryClick(business.id, index)}
                >
                  <div
                    className={cx({
                      AllBusinessesHeaderInstaStoryWatchedImage:
                        businessesWithStatus[index].isShowStatus,
                      AllBusinessesHeaderInstaStoryImage:
                        !businessesWithStatus[index].isShowStatus,
                    })}
                  >
                    <ImageWithPlaceholder
                      imageSource={createDOBucketName(business?.images?.logo)}
                      placeholder={IMAGE_ITEM_PLACEHOLDER}
                      alt="business-insta-image"
                    />
                  </div>
                  <div
                    className={cx({
                      AllBusinessesHeaderInstaStoryWatchedTitle:
                        businessesWithStatus[index].isShowStatus,
                      AllBusinessesHeaderInstaStoryTitle:
                        !businessesWithStatus[index].isShowStatus,
                    })}
                  >
                    <p className="h8">{business?.name}</p>
                  </div>
                </div>
                {showInstagramStoryId === business.id &&
                  showInstagramStory &&
                  business?.images?.other?.length > 0 && (
                    <InstaStories
                      business={business}
                      setShowInstaStory={setShowInstagramStory}
                    />
                  )}
              </React.Fragment>
            ))}
      </div>
    </div>
  );
};

AllBusinessesHeader.propTypes = {
  businesses: PropTypes.array,
  seeAll: PropTypes.bool,
  setSeeAll: PropTypes.func,
  searchBusinesses: PropTypes.string,
  setSearchBusinesses: PropTypes.func,
  handleSearchBusinesses: PropTypes.func,
};

export default AllBusinessesHeader;
