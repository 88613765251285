import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Search, { ENUMS as SEARCH_ENUMS } from "components/forms/search/Search";
import AddNewButton, {
  ENUMS as ADD_NEW_BUTTON_ENUMS,
} from "components/admin/buttons/add-new-button/AddNewButton";
import useAsync from "utils/hooks/useAsync";
import {
  filterByIsArchived,
  formatDateToShortFormat,
  handleOnAsyncError,
  handleOnAsyncSuccess,
} from "utils/helpers";
import {
  createBusiness,
  deleteBusiness,
  getAllBusinesses,
  updateBusiness,
} from "utils/api/services/business";
import useOutsideClick from "utils/hooks/useOutsideClick";
import AddBusinessModal from "pages/admin/admin-pages/super-admin/addmin-business-modal/AdminBusinessModal";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import { STORE_NAMES } from "utils/constants/redux";
import { resetUserBusiness, setUserBusiness } from "redux/slices/userStore";
import Table from "components/admin/elements/table/Table";
import { resetBusinessStore } from "redux/slices/businessStore";
import { commonAsyncErrorMessage } from "utils/constants/data/base";
import { resetReduxStoresForAdmin } from "utils/general";
import Spinner from "components/elements/spinner/Spinner";
import PhoneNumberFormatter from "components/elements/formatted-phone-number/FormattedPhoneNumber";

import "./SuperAdmin.scss";

const SuperAdmin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state[STORE_NAMES.user].user);
  const { plans } = useSelector((state) => state[STORE_NAMES.app]);

  const [businessFilterParams, setBusinessFilterParams] = useState({
    searchValue: "",
    selectedPlan: null,
    // selectedStatus: null,
  });

  const {
    result: allBusinessesWithArchived,
    loading,
    execute: executeGetAllBusinesses,
  } = useAsync(getAllBusinesses, {
    immediate: true,
  });
  const allBusinesses = allBusinessesWithArchived
    ? filterByIsArchived(allBusinessesWithArchived)
    : [];
  const handleOnAsyncSuccessForBusiness = (successMessage) => {
    handleOnAsyncSuccess(successMessage, () => {
      setOpenSlide(false);
      executeGetAllBusinesses();
    });
  };
  const { execute: executeCreateBusiness, loading: isLoadingCreateBusiness } =
    useAsync(createBusiness, {
      onError: (error) => {
        handleOnAsyncError(
          error.response.data.message || t(commonAsyncErrorMessage)
        );
      },
      onSuccess: () =>
        handleOnAsyncSuccessForBusiness(
          t("toastMessages.success.createBusiness")
        ),
    });
  const { execute: executeUpdateBusiness, loading: isLoadingUpdateBusiness } =
    useAsync(updateBusiness, {
      onError: () => handleOnAsyncError(t(commonAsyncErrorMessage)),
      onSuccess: () =>
        handleOnAsyncSuccessForBusiness(
          t("toastMessages.success.updateBusiness")
        ),
    });
  const { execute: executeDeleteBusiness } = useAsync(deleteBusiness, {
    onError: () => handleOnAsyncError(t(commonAsyncErrorMessage)),
    onSuccess: () =>
      handleOnAsyncSuccessForBusiness(
        t("toastMessages.success.deleteBusiness")
      ),
  });

  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [businessToEdit, setBusinessToEdit] = useState(null);
  const [openSlide, setOpenSlide, mainElementRef] = useOutsideClick();
  const [allBusinessesToDisplay, setAllBusinessesToDisplay] =
    useState(allBusinesses);

  useEffect(() => {
    setAllBusinessesToDisplay(allBusinesses);
  }, [allBusinessesWithArchived]);

  useEffect(() => {
    if (!loading && allBusinesses?.length > 0 && user.business?.id) {
      const businessByUserBusinessId = allBusinesses.find(
        (item) => item.id === user.business.id
      );
      businessByUserBusinessId && setSelectedBusiness(businessByUserBusinessId);
    }
  }, [loading, allBusinessesWithArchived, user.business]);

  useEffect(() => {
    filterBusinesses();
  }, [businessFilterParams]);

  const filterBusinesses = () => {
    let filteredBusinesses = allBusinesses;

    if (businessFilterParams.searchValue) {
      filteredBusinesses = filteredBusinesses.filter((business) =>
        business.name
          .toLowerCase()
          .includes(businessFilterParams.searchValue.toLowerCase())
      );
    }

    if (businessFilterParams.selectedPlan) {
      filteredBusinesses = filteredBusinesses.filter(
        (business) => business.plan.id === businessFilterParams.selectedPlan.id
      );
    }

    // if (businessFilterParams.selectedStatus) {
    //   filteredBusinesses = filteredBusinesses.filter(business => business.isArchived === businessFilterParams.selectedStatus.value);
    // }

    setAllBusinessesToDisplay(filteredBusinesses);
  };

  if (loading) {
    return <Spinner />;
  }

  const handleEditBusiness = (id) => {
    setBusinessToEdit(
      allBusinessesToDisplay.find((business) => business.id === id)
    );
    setOpenSlide(true);
  };

  const handleAddButtonClick = () => {
    setBusinessToEdit(null);
    setOpenSlide(true);
  };

  const handleBusinessSelect = (business) => {
    setSelectedBusiness(business);
    if (business) {
      dispatch(
        setUserBusiness({
          businessId: business.id,
          menuId: business.menu.id,
        })
      );
      resetReduxStoresForAdmin(dispatch);
    } else {
      dispatch(resetUserBusiness());
      dispatch(resetBusinessStore());
    }
  };

  const tableColumns = [
    {
      key: "name",
      name: t("inputs.name"),
      isFilterable: false,
    },
    {
      key: "contact",
      name: t("inputs.contact"),
      isFilterable: false,
    },
    // {
    //   key: "email",
    //   name: t("inputs.email"),
    //   isFilterable: false,
    // },
    {
      key: "planExpirationDate",
      name: `${t("business.plan")}`,
      isFilterable: false,
    },
    {
      key: "createDate",
      name: t("business.creationDate"),
      isFilterable: false,
    },
    // {
    //   key: "status",
    //   name: t("business.status"),
    //   isFilterable: false,
    // }
  ];

  const handleOnSaveBusiness = async (data, id, method) => {
    if (id) {
      const business = {
        planId: data.plan.id,
      };
      await executeUpdateBusiness(business, id);
    } else {
      const business = {
        name: data.name,
        emailOrPhone: data.email || data.phoneNumber.replace(/\+/g, ""),
        planId: data.plan.id,
        firstName: data.firstName,
        lastName: data.lastName,
      };
      await executeCreateBusiness(business, method);
    }
  };

  const handleOnDeleteBusiness = async (id) => {
    executeDeleteBusiness(id);
  };

  const tableItems =
    allBusinessesToDisplay?.map((business) => ({
      id: business.id,
      name: business.name,
      contact:
        (business.phoneNumber && (
          <PhoneNumberFormatter phoneNumber={business.phoneNumber} />
        )) ||
        business.email,
      // email: business.email,
      // planExpirationDate: `${business.plan?.name} ${formatDateToShortFormat(
      //   business.plan?.expirationDate
      // )}`, // TODO: `turn on this line when add expiration date to the plan`
      planExpirationDate: `${business.plan?.name}`,
      createDate: formatDateToShortFormat(business.createDate),
    })) || [];

  return (
    <div className="SuperAdmin">
      <h2 className="SemiBold SuperAdminTitle">
        {t("navbarRoutes.pageTitles.superAdmin")}
      </h2>
      <div className="SuperAdminHeader">
        <div className="SuperAdminHeaderLeft">
          <h4 className="SemiBold">{`${t("navbarRoutes.business")}(${
            allBusinesses.length
          })`}</h4>
        </div>
        <Dropdown
          placeholder={t("inputs.chooseCurrent")}
          options={allBusinesses || []}
          className="SuperAdminHeaderDropdown"
          onChange={handleBusinessSelect}
          value={selectedBusiness}
          search
        />
        <AddNewButton
          onClick={handleAddButtonClick}
          label={t("buttons.add")}
          type={ADD_NEW_BUTTON_ENUMS.types.TYPE_A}
        />
      </div>
      <div className="SuperAdminFilters">
        <Search
          onChange={(value) =>
            setBusinessFilterParams({
              ...businessFilterParams,
              searchValue: value,
            })
          }
          value={businessFilterParams.searchValue}
          type={SEARCH_ENUMS.types.TYPE_B}
          className="SuperAdminFiltersSearch"
          placeholder={t("inputs.search") + "..."}
        />
        <Dropdown
          placeholder={t("business.plan")}
          options={plans || []}
          className="SuperAdminFiltersPlan"
          onChange={(plan) =>
            setBusinessFilterParams({
              ...businessFilterParams,
              selectedPlan: plan,
            })
          }
          value={businessFilterParams.selectedPlan}
        />
        {/* <Dropdown
          placeholder={t("business.status")}
          options={statuses || []}
          className="SuperAdminFiltersStatus"
          onChange={(selectedStatus) => setBusinessFilterParams({ ...businessFilterParams, selectedStatus })}
          value={businessFilterParams.selectedStatus}
        /> */}
      </div>
      <Table
        columns={tableColumns}
        items={tableItems}
        onEdit={handleEditBusiness}
        hasEditColumnTitle
        classname="SuperAdminFiltersTable"
      />
      <AddBusinessModal
        mainElementRef={mainElementRef}
        setOpenSlide={setOpenSlide}
        openSlide={openSlide}
        businessToEdit={businessToEdit}
        onSave={handleOnSaveBusiness}
        onDelete={handleOnDeleteBusiness}
        isLoading={isLoadingCreateBusiness || isLoadingUpdateBusiness}
      />
    </div>
  );
};

export default SuperAdmin;
