import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { ReactComponent as CloseIcon } from "assets/icons/close/AdminClose.svg";
import { ReactComponent as CloseThinIcon } from "assets/icons/close/CloseThin.svg";

import "./CloseButton.scss";

export const ENUMS = {
  name: "CloseButton",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
    TYPE_C: "TYPE_C",
    TYPE_D: "TYPE_D",
    TYPE_E: "TYPE_E",
    TYPE_F: "TYPE_F",
    TYPE_G: "TYPE_G",
    TYPE_H: "TYPE_H",
    TYPE_R: "TYPE_R",
  },
};

const CloseButton = ({ type, onClick, className }) => {
  return (
    <button
      type="button"
      className={cx("CloseButton", className, {
        typeA: type === ENUMS.types.TYPE_A,
        typeB: type === ENUMS.types.TYPE_B,
        typeC: type === ENUMS.types.TYPE_C,
        typeD: type === ENUMS.types.TYPE_D,
        typeE: type === ENUMS.types.TYPE_E,
        typeF: type === ENUMS.types.TYPE_F,
        typeG: type === ENUMS.types.TYPE_G,
        typeH: type === ENUMS.types.TYPE_H,
        typeR: type === ENUMS.types.TYPE_R,
      })}
      onClick={onClick}
    >
      {type === ENUMS.types.TYPE_H || type === ENUMS.types.TYPE_R ? (
        <CloseThinIcon />
      ) : (
        <CloseIcon />
      )}
    </button>
  );
};

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,

  /**
   * The types of the component
   */
  type: PropTypes.oneOf([
    ENUMS.types.TYPE_A,
    ENUMS.types.TYPE_B,
    ENUMS.types.TYPE_C,
    ENUMS.types.TYPE_D,
    ENUMS.types.TYPE_E,
    ENUMS.types.TYPE_F,
    ENUMS.types.TYPE_G,
    ENUMS.types.TYPE_H,
    ENUMS.types.TYPE_R,
  ]),

  className: PropTypes.string,
};

CloseButton.defaultProps = {
  type: ENUMS.types.TYPE_A,
};
export default CloseButton;
