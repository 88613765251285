import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import { convertMeterToPixel } from "pages/admin/admin-pages/admin-map/helper";
import ReservationMapZoneTable from "pages/client/reservation/create-reservation/reservation-table-selection-type/reservation-zone-map/reservation-map-zone-table/ReservationMapZoneTable";
import { ReactComponent as Plus } from "assets/icons/plus/Plus.svg";
import { ReactComponent as Minus } from "assets/icons/math-operators/minus/MinusGuest.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/arrows/ArrowRight.svg";
import CloseButton, {
  ENUMS as ENUMS_CLOSE_BUTTON,
} from "components/buttons/close-button/CloseButton";
import CTAButton, {
  ENUMS as ENUMS_CTA_BUTTON,
} from "components/buttons/cta-button/CTAButton";

import "./ReservationMapZone.scss";
import ReservationStepperPreview from "../../reservation-stepper/reservation-stepper-preview/ReservationStepperPreview";

export const INITIAL_SIZE_CONTAINER = { width: 0, height: 0 };
export const INITIAL_SIZE_ZONE = { width: 10, height: 5 };

const ORDER_MAP_BORDER_WIDTH = 4;
const ORDER_MAP_EXTERNAL_PADDING = 0;
const MAP_ZOOM = {
  defaultScale: 1,
  minScale: 1,
  maxScale: 4,
  defaultPositionX: 200,
  defaultPositionY: 100,
};

const ReservationMapZone = ({
  onClose,
  onClickTable,
  selectedTable,
  zones,
  handleNextStep,
  formData,
  activeStep,
}) => {
  const { t } = useTranslation();
  const [selectedZone, setSelectedZone] = useState(zones[0]);
  const adminMapContainerRef = useRef(null);
  const [size, setSize] = useState(INITIAL_SIZE_CONTAINER);
  const [ratio, setRatio] = useState(0);
  const originalSizeOfZone = useMemo(
    () => ({
      width: selectedZone?.width || INITIAL_SIZE_ZONE.width,
      height: selectedZone?.height || INITIAL_SIZE_ZONE.height,
    }),
    [selectedZone]
  );

  const mapItems = useMemo(() => {
    if (selectedZone) {
      return selectedZone.tables
        .filter((table) =>
          Object.values(table.coordinates).every((value) => value !== null)
        )
        .map((table) => ({
          id: String(table.id),
          name: table.name,
          maxSeat: table.maxSeat,
          shape: table.shape,
          zoneId: selectedZone.id,
          ...table.coordinates,
        }));
    }
    return [];
  }, [selectedZone]);

  const findRatio = useCallback(
    ({ containerSize }) => {
      const { width: originalWidth } = originalSizeOfZone;
      const { width: containerWidth } = containerSize;
      setRatio(containerWidth / originalWidth);
    },
    [originalSizeOfZone]
  );

  const handleResize = useCallback(
    (entry) => {
      const updatedSizeOfContainer = {
        width:
          entry.contentRect.width -
          (ORDER_MAP_BORDER_WIDTH + ORDER_MAP_EXTERNAL_PADDING) * 2,
        height:
          entry.contentRect.height -
          (ORDER_MAP_BORDER_WIDTH + ORDER_MAP_EXTERNAL_PADDING) * 2,
      };
      setSize(updatedSizeOfContainer);
      findRatio({ containerSize: updatedSizeOfContainer });
    },
    [findRatio]
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        handleResize(entry);
      }
    });

    if (adminMapContainerRef.current) {
      resizeObserver.observe(adminMapContainerRef.current);
      const initialSize = {
        width:
          adminMapContainerRef.current.clientWidth -
          (ORDER_MAP_BORDER_WIDTH + ORDER_MAP_EXTERNAL_PADDING) * 2,
        height:
          adminMapContainerRef.current.clientHeight -
          (ORDER_MAP_BORDER_WIDTH + ORDER_MAP_EXTERNAL_PADDING) * 2,
      };
      setSize(initialSize);
      findRatio({ containerSize: initialSize });
    }

    return () => {
      if (adminMapContainerRef.current) {
        resizeObserver.unobserve(adminMapContainerRef.current);
      }
    };
  }, [handleResize, findRatio]);

  const displayedData = useMemo(() => {
    if (size && ratio && mapItems.length) {
      return mapItems.map((item) => ({
        ...convertMeterToPixel({ item, ratio }),
        id: parseInt(item.id),
      }));
    }
    return [];
  }, [size, ratio, mapItems]);

  return (
    <TransformWrapper
      defaultScale={MAP_ZOOM.defaultScale}
      minScale={MAP_ZOOM.minScale}
      maxScale={MAP_ZOOM.maxScale}
      defaultPositionX={MAP_ZOOM.defaultPositionX}
      defaultPositionY={MAP_ZOOM.defaultPositionY}
    >
      {({ zoomIn, zoomOut }) => (
        <div className="ReservationMap">
          <div className="ReservationMapHeader">
            <p className="ReservationMapHeaderTitle">Reservation map</p>
            <CloseButton
              onClick={onClose}
              type={ENUMS_CLOSE_BUTTON.types.TYPE_R}
              className={"ReservationMapHeaderCloseButton"}
            />
          </div>
          <ReservationStepperPreview
            formData={formData}
            activeStep={activeStep}
          />
          <div className="ReservationMapBody">
            <div className="ReservationMapBodyHeader">
              <Dropdown
                onChange={(zone) => setSelectedZone(zone)}
                options={zones}
                value={selectedZone}
              />
            </div>
            <div
              className={`ReservationMapBodyView `}
              ref={adminMapContainerRef}
            >
              <div
                className="ReservationMapBodyViewContainer"
                style={{
                  width: `${
                    originalSizeOfZone.width * ratio +
                    ORDER_MAP_BORDER_WIDTH * 2
                  }px`,
                  height: `${
                    originalSizeOfZone.height * ratio +
                    ORDER_MAP_BORDER_WIDTH * 2
                  }px`,
                  borderWidth: `${ORDER_MAP_BORDER_WIDTH}px`,
                }}
              >
                <TransformComponent>
                  <div
                    className="ReservationMapBodyViewContent"
                    style={{
                      width: `${originalSizeOfZone.width * ratio}px`,
                      height: `${originalSizeOfZone.height * ratio}px`,
                      position: "relative",
                    }}
                  >
                    {displayedData.map((tableData) => (
                      <ReservationMapZoneTable
                        key={tableData.id}
                        tableData={tableData}
                        onClickTable={onClickTable}
                        selectedTable={selectedTable}
                      />
                    ))}
                  </div>
                </TransformComponent>
              </div>
            </div>
            <div className="ReservationMapBodyViewFooter">
              <div className="ReservationMapBodyCheck">
                <div className="ReservationMapBodyCheckAllTables">
                  <div className="ReservationMapBodyCheckAllTablesStatus"></div>
                  <p className="ReservationMapBodyCheckAllTablesTitle">
                    {t("reservation.map.tables")}
                  </p>
                </div>
                <div className="ReservationMapBodyCheckSelectedTables">
                  <div className="ReservationMapBodyCheckSelectedTablesStatus"></div>
                  <p className="ReservationMapBodyCheckSelectedTablesTitle">
                    {t("reservation.selectedTable")}
                  </p>
                </div>
              </div>
              <div className="ReservationMapBodyZoomController">
                <h4 className="ReservationMapBodyZoomControllerTitle">Zoom:</h4>
                <div className="ReservationMapBodyZoomControllerButtons">
                  <div
                    className="ReservationMapBodyZoomControllerMinusButtonContainer"
                    onClick={() => zoomOut()}
                  >
                    <button className="ReservationMapBodyZoomControllerButton">
                      <Minus />
                    </button>
                  </div>
                  <div
                    className="ReservationMapBodyZoomControllerPlusButtonContainer"
                    onClick={() => zoomIn()}
                  >
                    <button className="ReservationMapBodyZoomControllerButton">
                      <Plus />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CTAButton
            onClick={handleNextStep}
            icon={<ArrowIcon />}
            name={t("buttons.continue")}
            type={ENUMS_CTA_BUTTON.types.TYPE_R}
          />
        </div>
      )}
    </TransformWrapper>
  );
};

export default ReservationMapZone;
