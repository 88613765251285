import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import useAsync from "utils/hooks/useAsync";
import { STORE_NAMES } from "utils/constants/redux";
import { CHART_PERIODS } from "components/buttons/chart-period-select-button/ChartPeriodSelectButton";
import StatsCardChartWithPeriodWrapper from "components/admin/charts/chart-wrappers/stat-chart-with-periods-wrapper/StatsCardChartWithPeriodWrapper";
import StatsNumberDataWrapper from "components/admin/charts/chart-wrappers/stats-number-data-wrapper/StatsNumberDataWrapper";
import VerticalBarChart from "components/admin/charts/vertical-bar-chart/VerticalBarChart";
import { getStatsOrderHistory } from "utils/api/services/dashboard";
import {
  fixCharDataWidth,
  formatChartDailyData,
  formatChartWeeklyData,
  formatOrderChartData,
  formatTime,
} from "utils/helper-functions/dashboardStatsHelper";
import EmptyState from "components/admin/empty-state/EmptyState";
import Spinner from "components/elements/spinner/Spinner";
import EmptyChartDataIcon from "assets/icons/admin-dashboard/EmptyChartDataIcon.svg";

import "./AdminDashOrderStats.scss";

const CHART_DATA_COLORS = {
  ordersFillColor: "#8D8D8D",
  ordersStrokeColor: "#000000",
  orderItemsFillColor: "#FFDD00",
  orderItemsStrokeColor: "#000000",
};

export const CHART_DATA_ORDER_HISTORY_KEYS = {
  ordersDataKey: "orders",
  orderItemsDataKey: "orderItems",
};

const getStatNumberData = (fetchRawData, t) => {
  const averageTime = formatTime(fetchRawData?.averageTime, t);

  return [
    {
      title: t("dashboard.orderStatistics.orderCount"),
      amount: `${fetchRawData?.orderCount || 0}`,
    },
    {
      title: t("dashboard.orderStatistics.guestsServed"),
      amount: `${fetchRawData?.guestsServer || 0}`,
    },
    {
      title: t("dashboard.orderStatistics.averageTime"),
      amount: `${averageTime || 0}`,
    },
  ];
};

const getOrderHistoryData = (
  fetchRawData,
  selectedPeriod,
  fromDateToDate,
  t
) => {
  const CHART_DATA_KEYS = {
    xAxisDataKey: selectedPeriod,
    ordersDataKey: CHART_DATA_ORDER_HISTORY_KEYS.ordersDataKey,
    orderItemsDataKey: CHART_DATA_ORDER_HISTORY_KEYS.orderItemsDataKey,
    salesData: "salesData",
  };
  const fromDate = new Date(fromDateToDate.from);
  const toDate = new Date(fromDateToDate.to);

  let data = formatOrderChartData(
    fetchRawData,
    CHART_DATA_KEYS.salesData,
    selectedPeriod,
    CHART_DATA_KEYS.xAxisDataKey,
    CHART_DATA_KEYS.ordersDataKey,
    CHART_DATA_KEYS.orderItemsDataKey,
    true
  );
  if (selectedPeriod === CHART_PERIODS.daily) {
    data = formatChartDailyData(data, fromDate, toDate, t);
  } else if (selectedPeriod === CHART_PERIODS.weekly) {
    data = formatChartWeeklyData(
      data,
      CHART_DATA_KEYS.ordersDataKey,
      CHART_DATA_KEYS.orderItemsDataKey,
      t
    );
  }

  return {
    meta: {
      type: "monotone",
      xAxisDataKey: CHART_DATA_KEYS.xAxisDataKey,
      data: [
        {
          dataKey: CHART_DATA_KEYS.ordersDataKey,
          legendName: t("dashboard.legends.orders"),
          fillColor: CHART_DATA_COLORS.ordersFillColor,
          strokeColor: CHART_DATA_COLORS.ordersStrokeColor,
          stackId: "1",
        },
        {
          dataKey: CHART_DATA_KEYS.orderItemsDataKey,
          legendName: t("dashboard.legends.orderItems"),
          fillColor: CHART_DATA_COLORS.orderItemsFillColor,
          strokeColor: CHART_DATA_COLORS.orderItemsStrokeColor,
          stackId: "2",
        },
      ],
    },
    data,
  };
};

const AdminDashOrderStats = () => {
  const { t } = useTranslation();

  const { setTitle, fromDateToDate } = useOutletContext();
  const [selectedPeriod, setSelectedPeriod] = useState(CHART_PERIODS.hourly);

  let chartDataWidth = "100%";

  useEffect(() => {
    setTitle(t("navbarRoutes.pageTitles.orderStatistics"));
  }, [t]);

  const businessId = useSelector(
    (state) => state[STORE_NAMES.user].user?.business.id
  );

  const { result: fetchedRawData, loading: orderStatsHistoryLoading } =
    useAsync(getStatsOrderHistory, {
      immediate: true,
      params: [businessId, fromDateToDate.from, fromDateToDate.to],
    });

  const statsNumberData = getStatNumberData(fetchedRawData, t);

  const statsOrderHistoryData = getOrderHistoryData(
    fetchedRawData,
    selectedPeriod,
    fromDateToDate,
    t
  );

  switch (selectedPeriod) {
    case CHART_PERIODS.hourly:
      chartDataWidth = "195%";
      break;
    case CHART_PERIODS.daily:
      chartDataWidth = fixCharDataWidth(
        statsOrderHistoryData?.data?.length,
        8,
        20
      );
      break;
    case CHART_PERIODS.weekly:
      chartDataWidth = fixCharDataWidth(
        statsOrderHistoryData?.data?.length,
        6,
        15
      );
      break;
    case CHART_PERIODS.monthly:
      break;
    default:
      chartDataWidth = "100%";
  }

  const controlChartData = statsOrderHistoryData?.data.every(
    (day) => day.orders === 0 && day.orderItems === 0
  );

  return (
    <div className="AdminDashOrderStats">
      {orderStatsHistoryLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <>
          <StatsNumberDataWrapper data={statsNumberData} />

          <StatsCardChartWithPeriodWrapper
            activeButton={selectedPeriod}
            setActiveButton={setSelectedPeriod}
            title={t("dashboard.statistics")}
            legendData={statsOrderHistoryData?.meta?.data}
            disableMonthlyButton={true}
            chartToRender={
              statsOrderHistoryData.data ? (
                !controlChartData ? (
                  <VerticalBarChart
                    chartData={statsOrderHistoryData}
                    chartWidth={chartDataWidth}
                  />
                ) : (
                  <EmptyState
                    icon={EmptyChartDataIcon}
                    isAdmin={true}
                    description={t("dashboard.noResultForThisPeriodMsg")}
                    descriptionClassName={
                      "AdminOrderHistoryEmptyStateDescription"
                    }
                  />
                )
              ) : (
                <div>
                  <Spinner />
                </div>
              )
            }
          />
        </>
      )}
    </div>
  );
};

export default AdminDashOrderStats;
