import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ROUTE_NAME } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";
import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import InstagramIcon from "assets/icons/sosial-media/FooterInstagram.svg";
import WhatsappIcon from "assets/icons/sosial-media/FooterWhatsapp.svg";
import MailIcon from "assets/icons/sosial-media/FooterMail.svg";
import { createDOBucketName } from "utils/DO-Spaces";
import FooterNavButton from "components/buttons/footer-nav-button/FooterNavButton";
import FooterSocial from "components/elements/footer-social/FooterSocial";
import {
  getBrandFooterLogo,
  getBrandName,
  getBrandURL,
} from "utils/branding-helper";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";
import FormattedPhoneNumber from "components/elements/formatted-phone-number/FormattedPhoneNumber";

import "./Footer.scss";

const FooterLogo = getBrandFooterLogo();
const brandName = getBrandName();
const URL = getBrandURL();

const Footer = ({ scrollElement }) => {
  const business = useSelector((state) => state[STORE_NAMES.business].business);
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const { table } = useSelector((state) => state[STORE_NAMES.qrScan]);
  const tableId = table?.id;
  const order = orders?.find((order) => order.table.id === tableId);
  const isOrderEmpty = !order;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleMenuButtonClick = () => {
    scrollElement.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleDashboardClick = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business?.id}${ROUTE_NAME.dashboard}`
    );
  };

  const handleFavoritesAndBasketClick = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business?.id}${ROUTE_NAME.basket}`
    );
  };

  const handleAboutUsButtonClick = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business?.id}${ROUTE_NAME.info}`
    );
  };

  const handleChatClick = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business?.id}${ROUTE_NAME.chat}`
    );
    window.scrollTo({ top: 0 });
  };
  const fullAddress = [
    business.address.country,
    business.address.city,
    business.address.street,
  ]
    .filter((item) => item !== undefined && item !== "" && item !== null)
    .join(", ");
  const anySocialAddress =
    business?.contact?.instagram.value ||
    business?.contact?.gmail.value ||
    business?.contact?.whatsapp.value;
  const anyContact =
    business?.contact?.phone.value ||
    business?.address.city ||
    business?.address.country ||
    business?.address.street;
  return (
    <div className="FooterContainer">
      <div className="FooterLogoContainer">
        <div className="FooterLogoFront">
          <ImageWithPlaceholder
            imageSource={createDOBucketName(business?.images.logo)}
            placeholder={IMAGE_ITEM_PLACEHOLDER}
            alt="logo"
            className="FooterLogoImage"
          />
        </div>
        <h4 className="Bold">{business.name}</h4>
      </div>

      <div className="FooterLinksContainer">
        <div className="FooterLinksPages">
          <h5 className="SemiBold">{t("common.pages")}</h5>
          <FooterNavButton
            title={t("navbarRoutes.menu")}
            onClick={handleMenuButtonClick}
          />
          {!isOrderEmpty && (
            <FooterNavButton
              title={t("basket.order.myOrders")}
              onClick={handleDashboardClick}
            />
          )}
          <FooterNavButton
            title={t("basket.basketAndFavorites")}
            onClick={handleFavoritesAndBasketClick}
          />
          <FooterNavButton
            title={t("common.about")}
            onClick={handleAboutUsButtonClick}
          />
          {!isOrderEmpty && business?.chat && (
            <FooterNavButton title={t("chat.chat")} onClick={handleChatClick} />
          )}
        </div>
        {anyContact && (
          <div className="FooterLinksContact">
            <h5 className="SemiBold">{t("common.contact")}</h5>
            {business.contact.phone.value && (
              <h6
                onClick={() =>
                  window.open(`tel:${business.contact.phone.value}`, "_blank")
                }
              >
                <FormattedPhoneNumber
                  phoneNumber={business.contact.phone.value}
                  className="textWhite h6"
                />
              </h6>
            )}
            <h6
              onClick={() => window.open(business.address.googleLink, "_blank")}
            >
              {fullAddress}
            </h6>
          </div>
        )}
      </div>
      {anySocialAddress && (
        <div className="FooterSocialsContainer">
          {business.contact.instagram.value && (
            <FooterSocial
              SocialIcon={InstagramIcon}
              onClick={() =>
                window.open(business.contact.instagram.value, "_blank")
              }
            />
          )}
          {business.contact.whatsapp.value && (
            <FooterSocial
              SocialIcon={WhatsappIcon}
              onClick={() =>
                window.open(
                  `https://wa.me/${business.contact.whatsapp.value}`,
                  "_blank"
                )
              }
            />
          )}
          {business.contact.gmail.value && (
            <FooterSocial
              SocialIcon={MailIcon}
              onClick={() =>
                window.open(`mailto:${business.contact.gmail.value}`, "_blank")
              }
            />
          )}
        </div>
      )}
      <div className="FooterPrivacyAndTerms">
        <div className="CompanyName">
          <h6>
            <Trans
              i18nKey="general.madeBy"
              components={{
                strong: (
                  <a
                    href={URL.address}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={"CompanyNameLink"}
                  >
                    {brandName}
                  </a>
                ),
              }}
            />
          </h6>
        </div>
        <div className="FooterCopyRightContainer">
          <h6>{t("general.allRightReserved")}</h6>
          <div className="FooterCopyRight">
            <FooterLogo />
            <h6>{t("general.copyRight", { value: brandName })}</h6>
          </div>
          <a href={URL.address} target="_blank" rel="noopener noreferrer">
            {URL.name}
          </a>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  scrollElement: PropTypes.object,
};

export default Footer;
