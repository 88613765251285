import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactConfetti from "react-confetti";

import useWindowSize from "utils/hooks/useWindowSize";
import { STORE_NAMES } from "utils/constants/redux";
import DashboardHeroSection from "pages/client/dashboard/dashboard-hero-section/DashboardHeroSection";
import GuestInformation, {
  ENUMS,
} from "components/elements/guest-information/GuestInformation";
import {
  calculateAllOrdersDiscountPrice,
  calculateAllOrdersPrice,
  calculateAllOrdersTotalPrice,
  calculateConfirmedOrderItemsCount,
  calculateServiceFee,
} from "utils/general";
import GuestProfiles from "components/elements/guest-profiles/GuestProfiles";
import DashboardOrders from "components/elements/dashboard-orders/DashboardOrders";
import PaymentModal from "components/payment-modal/PaymentModal";
import useOutsideClick from "utils/hooks/useOutsideClick";

import IMG_GUEST from "assets/images/placeholder/GuestPlaceholder.png";
import { createDOBucketName } from "utils/DO-Spaces";
import { generateGuestIdSuffix } from "utils/helpers";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import useTimeout from "utils/hooks/useTimeout";
import { ENUMS as ENUMS_CLOSE_BUTTON } from "components/buttons/close-button/CloseButton";
import WheelOfFortuneBanner from "components/wheel-of-fortune/WheelOfFortuneBanner";

import "./Dashboard.scss";

const Dashboard = () => {
  const { width, height } = useWindowSize().size;
  let [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedGuests, setSelectedGuests] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [menuItemCount, setMenuItemCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isReviewRequestSuccessful, setIsReviewRequestSuccessful] =
    useState(null);
  const businessImage = createDOBucketName(
    useSelector((state) => state[STORE_NAMES.business]).business.images.logo
  );
  const qrScanStore = useSelector((state) => state[STORE_NAMES.qrScan]);
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const tableId = qrScanStore.table.id;
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const order = orders?.find((order) => order?.table.id === tableId);
  const isOrderEmpty = !order;
  const { serviceFee } = useSelector(
    (state) => state[STORE_NAMES.business].business
  );

  const { business: businessStore } = useSelector(
    (state) => state[STORE_NAMES.business]
  );
  const [openPayment, setOpenPayment, mainElementRef] = useOutsideClick();
  const [showConfetti, setShowConfetti] = useState(
    Boolean(searchParams.get(QUERY_PARAMS.showConfetti))
  );

  const showConfettiWhenConfirmOrder = () => {
    setShowConfetti(false);
    searchParams.delete(QUERY_PARAMS.showConfetti);
    setSearchParams(searchParams);
  };

  useTimeout({
    callback: showConfettiWhenConfirmOrder,
    delay: 5000,
  });

  const allOrderers = useMemo(() => {
    if (!order) return [];
    return [
      ...(order.guests?.map((guest) => ({
        ...guest,
        person: {
          ...guest.person,
          id: generateGuestIdSuffix(guest.person.id),
          originalId: guest.person.id,
          title: guest.name || t("dashboard.guest.guest"),
          image: createDOBucketName(guest.profilePic) || IMG_GUEST,
        },
      })) || []),
      ...(order.users?.map((user) => ({
        ...user,
        person: {
          ...user.person,
          title: `${user.name}`,
          image: businessImage || IMG_GUEST,
        },
      })) || []),
    ];
  }, [order, t, businessImage]);

  const promo = order?.priceSummary.promo;
  const disablePayButton =
    menuItemCount === 0 || !businessStore?.isAskForBillEnabled;

  useEffect(() => {
    isOrderEmpty &&
      navigate(
        `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessStore.id}${ROUTE_NAME.menu}`
      );
  }, [isOrderEmpty]);

  useEffect(() => {
    let guests;
    if (selectedOrders.length > 0 || selectedGuests.length > 0) {
      guests = allOrderers.map((guest) => {
        return {
          ...guest,
          orderItems: guest.orderItems.filter((orderItem) =>
            selectedOrders.includes(orderItem.id)
          ),
        };
      });
    } else {
      guests = allOrderers;
    }
    setMenuItemCount(calculateConfirmedOrderItemsCount(guests));
    const subTotal = calculateAllOrdersPrice(guests);
    const discountPrice = calculateAllOrdersDiscountPrice(guests);
    const serviceFeeTotal = calculateServiceFee(
      subTotal,
      discountPrice,
      serviceFee
    );
    const promoValue =
      promo && !promo.discount.isFixed
        ? (subTotal * promo.discount.rate) / 100
        : 0;
    setTotalPrice(
      calculateAllOrdersTotalPrice(
        subTotal,
        0,
        promoValue,
        serviceFeeTotal,
        discountPrice
      )
    );
  }, [selectedGuests, selectedOrders, promo, order]);

  const handleSelectGuest = (selectedGuestId, isAllSelected) => {
    if (isAllSelected) {
      setSelectedGuests([]);
      return;
    }

    const isSelected = selectedGuests.find(
      (guestId) => guestId === selectedGuestId
    );
    const selectedGuestOrderItems = allOrderers
      .find((user) => user.person.id === selectedGuestId)
      .orderItems.flatMap((orderItem) => orderItem.id);

    if (isSelected) {
      setSelectedGuests((prev) =>
        prev.filter((guestId) => guestId !== selectedGuestId)
      );
      setSelectedOrders((prev) =>
        prev.filter((item) => !selectedGuestOrderItems.includes(item))
      );
    } else {
      setSelectedGuests((prev) => [...prev, selectedGuestId]);
      setSelectedOrders((prev) => [...prev, ...selectedGuestOrderItems]);
    }
  };

  const handlePayClick = () => {
    setOpenPayment(true);
  };

  useEffect(() => {
    if (typeof isReviewRequestSuccessful === "boolean") {
      const timeoutId = setTimeout(() => {
        setIsReviewRequestSuccessful(null);
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [isReviewRequestSuccessful]);

  return (
    <div className="DashBoard">
      {showConfetti && (
        <ReactConfetti
          width={window.innerWidth <= 1000 ? width : 500}
          height={window.innerHeight <= 1000 ? height : 700}
          recycle={false}
          numberOfPieces={500}
        />
      )}
      <DashboardHeroSection
        menuItemCount={menuItemCount}
        selectedGuests={
          selectedGuests.length > 0
            ? allOrderers.filter((guest) =>
                selectedGuests.includes(guest.person.id)
              )
            : allOrderers
        }
      />
      {order && (
        <>
          <div className="DashBoardInformationSection">
            <WheelOfFortuneBanner
              title={"Who?"}
              texts={[
                t("funZone.wheelOfFortune.whoWillPayTheBill"),
                t("funZone.wheelOfFortune.whoWillGoToTheMarket"),
                t("funZone.wheelOfFortune.whoWillHaveAnotherShot"),
                t("funZone.wheelOfFortune.whoWillGetDessert"),
                t("funZone.wheelOfFortune.whoWillChooseTheMusic"),
                t("funZone.wheelOfFortune.whoWillSingKaraoke"),
              ]}
            />
            <div className="DashBoardGuestCount">
              {allOrderers.length > 0 && (
                <h6 className="Medium">
                  {allOrderers.length}{" "}
                  {allOrderers.length === 1
                    ? t("dashboard.guest.guest").toLowerCase()
                    : t("dashboard.guest.guests").toLowerCase()}
                </h6>
              )}
            </div>
            <div className="ProfilesAndOrders">
              <GuestProfiles
                guests={allOrderers}
                onClick={handleSelectGuest}
                selectedGuests={allOrderers.filter((guest) =>
                  selectedGuests.includes(guest.person.id)
                )}
                guestId={generateGuestIdSuffix(guestId)}
                tableName={qrScanStore.table?.name}
              />

              <DashboardOrders
                guests={allOrderers}
                setSelectedGuests={setSelectedGuests}
                selectedOrders={selectedOrders}
                setSelectedOrders={setSelectedOrders}
                selectedGuests={selectedGuests}
              />
            </div>
          </div>

          <div className="DashBoardGuestInformation">
            <GuestInformation
              totalPrice={totalPrice}
              handlePayClick={handlePayClick}
              menuItemCount={menuItemCount}
              type={ENUMS.types.TYPE_C}
              isPayButtonDisabled={disablePayButton}
              guestCount={allOrderers.length}
              tableName={qrScanStore.table?.name}
            />
          </div>
          <PaymentModal
            mainElementRef={mainElementRef}
            openSlide={openPayment}
            onClose={() => setOpenPayment(false)}
            totalPrice={totalPrice}
            menuItemCount={menuItemCount}
            tableName={qrScanStore.table?.name}
            closeButtonType={ENUMS_CLOSE_BUTTON.types.TYPE_F}
            selectedGuests={
              selectedGuests.length > 0
                ? allOrderers.filter((guest) =>
                    selectedGuests.includes(guest.person.id)
                  )
                : allOrderers
            }
          />
        </>
      )}
    </div>
  );
};

export default Dashboard;
