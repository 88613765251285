import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Price, { ENUMS as PRICE_ENUMS } from "components/elements/price/Price";
import { getBrandMainLogoLight } from "utils/branding-helper";

const MainLogo = getBrandMainLogoLight();

import "./OrderInfo.scss";

export const ENUMS = {
  name: "OrderInfo",
};

const OrderInfo = ({
  serviceFee,
  totalPrice,
  subtotal,
  serviceFeeTotal,
  discountPrice,
}) => {
  const { t } = useTranslation();

  return (
    <div className="OrderInfoWrapper">
      <div className="OrderInfo">
        <div className="OrderInfoTitle">
          <div className="OrderInfoTitleLogo">
            <MainLogo />
          </div>
          <span className="SemiBold">{t("basket.order.info")}</span>
        </div>

        <div className="OrderInfoCenter">
          <div className="OrderInfoDetail">
            <h6>{t("basket.order.subtotal")}</h6>
            <h6>
              <Price
                value={subtotal}
                type={PRICE_ENUMS.types.PRICE_S_REGULAR}
              />
            </h6>
          </div>
          {/*<div className="OrderInfoDetail">*/}
          {/*  <h6>{`${t("basket.order.tax")} ${extraFees.tax}%`}</h6>*/}
          {/*  <h6>*/}
          {/*    <Price value={tax} type={PRICE_ENUMS.types.PRICE_S_REGULAR} />*/}
          {/*  </h6>*/}
          {/*</div>*/}
          <div className="OrderInfoDetail">
            <h6>{`${t("inputs.serviceFee")} ${serviceFee}%`}</h6>
            <h6>
              <Price
                value={serviceFeeTotal}
                type={PRICE_ENUMS.types.PRICE_S_REGULAR}
              />
            </h6>
          </div>
          <div className="OrderInfoDetail">
            <h6>{`${t("inputs.discount")}`}</h6>
            <h6>
              <Price
                value={discountPrice}
                type={PRICE_ENUMS.types.PRICE_S_REGULAR}
              />
            </h6>
          </div>
        </div>
        <div className="OrderInfoBorder">
          <div className="OrderInfoLine" />
        </div>
        <div className="OrderInfoTotal">
          <h3 className="SemiBold">{t("basket.order.total")}</h3>
          <h3 className="SemiBold">
            <Price
              value={totalPrice}
              type={PRICE_ENUMS.types.PRICE_S_REGULAR}
            />
          </h3>
        </div>
      </div>
    </div>
  );
};

OrderInfo.propTypes = {
  /**
   * The service fee amount for the order.
   */
  serviceFee: PropTypes.number,

  /**
   * The total price of the order.
   */
  totalPrice: PropTypes.number,

  /**
   * The subtotal price of the order.
   */
  subtotal: PropTypes.number.isRequired,

  /**
   * The total service fee amount for the order.
   */
  serviceFeeTotal: PropTypes.number,

  discountPrice: PropTypes.number,
};

export default OrderInfo;
