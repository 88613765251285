import React from "react";
import { Trans, useTranslation } from "react-i18next";

import FooterSocial from "components/elements/footer-social/FooterSocial";
import {
  getBrandMainLogoLight,
  getBrandName,
  getBrandURL,
} from "utils/branding-helper";
import InstagramIcon from "assets/icons/sosial-media/FooterInstagram.svg";
import WhatsappIcon from "assets/icons/sosial-media/FooterWhatsapp.svg";
import MailIcon from "assets/icons/sosial-media/FooterMail.svg";
import FormattedPhoneNumber from "components/elements/formatted-phone-number/FormattedPhoneNumber";

import "./AllBusinessesFooter.scss";

const initFooterData = {
  contact: {
    phoneNumber: "+99450 600 60 11",
    address: "Koroglu Rahimov Street 61 Baku, Azerbaijan",
    googleLink: "   https://maps.app.goo.gl/JHzNeAj7RsnHN4M37",
  },
  socials: {
    instagram: "https://www.instagram.com/onezero_group/",
    email: "develop.onezero@gmail.com",
  },
};

const AllBusinessesFooter = () => {
  const { t } = useTranslation();

  const MainLogoLight = getBrandMainLogoLight();
  const brandName = getBrandName();
  const URL = getBrandURL();

  return (
    <div className="FooterContainer">
      <div className="FooterLogoContainer">
        <div className="FooterLogoFront">
          <MainLogoLight />
        </div>
        <h4 className="Bold">{brandName}</h4>
      </div>
      <div className="FooterLinksContainer">
        <div className="FooterLinksContact">
          <h5 className="SemiBold">{t("common.contact")}</h5>
          <h6
            onClick={() =>
              window.open(`tel:${initFooterData.contact.phoneNumber}`, "_blank")
            }
          >
            <FormattedPhoneNumber
              phoneNumber={initFooterData.contact.phoneNumber}
              className="textWhite h6"
            />
          </h6>
          <h6
            onClick={() =>
              window.open(`tel:${initFooterData.contact.phoneNumber}`, "_blank")
            }
          >
            <FormattedPhoneNumber
              phoneNumber={initFooterData.contact.phoneNumber}
              className="textWhite h6"
            />
          </h6>
          <h6
            onClick={() =>
              window.open(initFooterData.contact.googleLink, "_blank")
            }
          >
            {initFooterData.contact.address}
          </h6>
        </div>
      </div>
      <div className="FooterSocialsContainer">
        <FooterSocial
          SocialIcon={InstagramIcon}
          onClick={() =>
            window.open(initFooterData.socials.instagram, "_blank")
          }
        />
        <FooterSocial
          SocialIcon={WhatsappIcon}
          onClick={() =>
            window.open(
              `https://wa.me/${initFooterData.contact.phoneNumber}`,
              "_blank"
            )
          }
        />
        <FooterSocial
          SocialIcon={MailIcon}
          onClick={() =>
            window.open(`mailto:${initFooterData.socials.email}`, "_blank")
          }
        />
      </div>
      <div className="FooterPrivacyAndTerms">
        <div className="CompanyName">
          <h6>
            <Trans
              i18nKey="general.madeBy"
              components={{
                strong: (
                  <a
                    href={URL.address}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={"CompanyNameLink"}
                  >
                    {brandName}
                  </a>
                ),
              }}
            />
          </h6>
        </div>
        <div className="FooterCopyRightContainer">
          <h6>{t("general.allRightReserved")}</h6>
          <div className="FooterCopyRight">
            <h6>{t("general.copyRight", { value: brandName })}</h6>
          </div>
          <a href={URL.address} target="_blank" rel="noopener noreferrer">
            {URL.name}
          </a>
        </div>
      </div>
    </div>
  );
};
export default AllBusinessesFooter;
