const TRANSLATION = {
  welcome: "Willkommen",
  common: {
    settings: "Einstellungen",
    about: "Über",
    home: "Startseite",
    logo: "Logo",
    shape: "Form",
    selected: "Ausgewählt",
    create: "Erstellen",
    notFound: "Nicht Gefunden",
    applied: "Angewendet",
    yourLikes: "Deine Likes",
    details: "Details",
    published: "Veröffentlicht",
    unPublished: "Unveröffentlicht",
    general: "Allgemein",
    address: "Adresse",
    wifi: "WLAN",
    copied: "Kopiert",
    contact: "Kontakt",
    pages: "Seiten",
    kcal: "kcal",
    currency: "Währung",
    connect: "Verbinden",
    qr: "QR",
    zoneAndTable: "Zone & Tisch",
    connectToWifi: "Mit WLAN verbunden",
    disconnectFromWifi: "Von WLAN getrennt",
    name: "Name",
    date: {
      date: "Datum",
      time: "Zeit",
      createdAt: "Erstellt am",
      dateCreated: "Erstellungsdatum",
      dateSelection: "Datumauswahl",
      schedule: "Betriebszeiten",
    },
    time: {
      hour: "Stunde",
      minute: "Minute",
      second: "Sekunde",
      from: "Von",
      to: "Bis",
      since: "Seit Beginn",
      min: "min",
      shortVersion: {
        hour: "Std",
        minute: "Min",
        second: "Sek",
      },
    },
    days: {
      days: "Tage",
      monday: "Montag",
      tuesday: "Dienstag",
      wednesday: "Mittwoch",
      thursday: "Donnerstag",
      friday: "Freitag",
      saturday: "Samstag",
      sunday: "Sonntag",
      everyday: "Jeden Tag",
      everyWeekday: "Jeden Wochentag",
      weekend: "Wochenende",
    },
  },
  navbarRoutes: {
    statistics: "Statistiken",
    salesStatistics: "Verkaufsstatistik",
    orderStatistics: "Bestellstatistik",
    allOrders: "Alle Bestellungen",
    menuStatistics: "Menüstatistiken",
    allItems: "Alle Artikel",
    staff: "Mitarbeiter",
    weeklyStatistics: "Wöchentliche Statistiken",
    menu: "Menü",
    business: "Geschäft",
    users: "Benutzer",
    qrAndTable: "QR & Tisch",
    table: "Tisch",
    qr: "QR",
    reservation: "Reservierung",
    map: "Karte",
    orders: "Bestellungen",
    departments: "Abteilungen",
    superAdmin: "Super Admin",
    inventory: "Inventar",
    funZone: "Spielzone",
    guests: "Gäste",
    pageTitles: {
      superAdmin: "SUPER ADMIN",
      infra: "INFRA",
      guests: "GÄSTE",
      weeklyStatistics: "WÖCHENTLICHE STATISTIKEN",
      salesStatistics: "VERKAUFSSTATISTIK",
      orderStatistics: "BESTELLSTATISTIK",
      menuStatistics: "MENÜSTATISTIKEN",
      allOrders: "ALLE BESTELLUNGEN",
      allItems: "ALLE ARTİKEL",
      category: "KATEGORIE",
      settings: "EINSTELLUNGEN",
      business: "GESCHÄFT",
      users: "BENUTZER",
      zones: "ZONEN",
      tables: "TISCHE",
      qrList: "QR-LISTE",
    },
  },
  emptyTable: {
    emptyTableDescription: "Sie haben noch keine Zonen.",
    emptyZoneDescription: "In dieser Zone gibt es keine Tische.",
    emptyQR: "Sie haben noch keine QR-Codes.",
    emptyZoneTitle: "Es gibt keine Tische auf der Karte.",
  },
  language: {
    language: "Sprache",
    english: "Englisch",
    russian: "Russisch",
    azerbaijani: "Aserbaidschanisch",
    german: "Deutsch",
  },
  buttons: {
    addItem: "Artikel hinzufügen",
    addQR: "QR hinzufügen",
    back: "Zurück",
    createQR: "QR erstellen",
    createZone: "Zone erstellen",
    createOrder: "Bestellung erstellen",
    edit: "Bearbeiten",
    delete: "Löschen",
    confirm: "Bestätigen",
    finish: "Fertigstellen",
    discard: "Verwerfen",
    change: "Ändern",
    deny: "Ablehnen",
    save: "Speichern",
    close: "Schließen",
    saveChanges: "Änderungen speichern",
    all: "Alle",
    selectAll: "Alle auswählen",
    seeAll: "Alle anzeigen",
    seeLess: "Weniger anzeigen",
    add: "Hinzufügen",
    addNew: "Neu hinzufügen",
    reset: "Zurücksetzen",
    resend: "Erneut senden",
    clearFilters: "Filter löschen",
    pay: "Bezahlen",
    clear: "Alles löschen",
    apply: "Anwenden",
    defaultLanguage: "Standardsprache",
    defaultCurrency: "Standardwährung",
    availableLanguages: "Verfügbare Sprachen",
    currency: "Währung",
    link: "Verknüpfung",
    links: "Verknüpfungen",
    unlink: "Verknüpfung aufheben",
    addBasket: "Zum Warenkorb hinzufügen",
    addToOrder: "Zur Bestellung hinzufügen",
    clickToAdd: "Klicken zum Hinzufügen",
    clickToAddImage: "Klicken zum Bild hinzufügen",
    clickToUpload: "Klicken zum Hochladen",
    logOut: "Abmelden",
    login: "Anmelden",
    lockScreen: "Sperren",
    goToDashboard: "Zum Dashboard",
    rememberMe: "Angemeldet bleiben",
    forgotPassword: "Passwort vergessen?",
    payWith: "Bezahlen mit",
    seeMenu: "Menü ansehen",
    addOrder: "Neue Bestellung",
    readMore: "Mehr lesen",
    serve: "Servieren",
    confirmOrder: "Bestellung bestätigen",
    backToMenu: "Zurück zum Menü",
    send: "Senden",
    tryAgain: "Erneut versuchen",
    update: "Aktualisieren",
    continue: "Weiter",
    verify: "Überprüfen",
    applyDiscount: "Rabatt anwenden",
    print: "Drucken",
    closeOrder: "Bestellung abschließen",
    printBill: "Rechnung drucken",
    fullTables: "Volle Tische",
    editForModal: "{{title}} bearbeiten",
    addForModal: " {{title}} hinzufügen ",
    successfullySent: "Erfolgreich Gesendet",
    sent: "Gesendet",
    reserve: "Reservieren",
    skip: "Überspringen",
    source: "Quelle",
    target: "Ziel",
    editZone: "Zone Bearbeiten",
    contactUs: "Kontaktieren Sie uns",
  },
  inputs: {
    name: "Name",
    username: "Benutzername",
    description: "Beschreibung",
    price: "Preis",
    discount: "Rabatt",
    role: "Rolle",
    phoneNumber: "Telefonnummer",
    pinCode: "PIN-Code",
    pinCodeConfirm: "PIN-Code bestätigen",
    firstName: "Vorname",
    lastName: "Nachname",
    email: "E-Mail",
    password: "Passwort",
    confirmPassword: "Passwort bestätigen",
    newPassword: "Neues Passwort",
    businessName: "Firmenname",
    city: "Stadt",
    zipCode: "Postleitzahl",
    street: "Straße",
    map: "Google Maps Link",
    serviceFee: "Servicegebühr",
    search: "Suche",
    enterPromoCode: "Promo-Code eingeben",
    link: "Verknüpfung hinzufügen",
    instagram: "Instagram Link",
    phone: "Telefon hinzufügen",
    contactPhone: "Telefonnummer",
    whatsapp: "Whatsapp-Nummer",
    mail: "E-Mail-Adresse hinzufügen",
    contactMail: "E-Mail-Adresse",
    tableName: "Tischnummer",
    usernameExample: "@beispiel",
    chooseCurrent: "Aktuelles wählen",
    seats: "Sitzplätze",
    minDeposit: "Min. Anzahlung",
    qrName: "QR-Name",
    wifiName: "WLAN-Name",
    sell: "Verkaufspreis",
    cost: "Kosten",
    calories: "Kalorien",
    time: "Zeit",
    amount: "Menge",
    unit: "Einheit",
    addComment: "Kommentar hinzufügen",
    warningAmount: "Warnmenge",
    number: "Nummer",
    notFixed: "Nicht fixiert",
    fixed: "Fixiert",
    maxNumber: "Maximale Anzahl",
    deposit: "Anzahlung",
    yourName: "Ihr Name",
    contact: "Kontakt",
    reserveeName: "Name des Reservierenden",
    numberOfGuest: "Anzahl der Gäste",
    date: "Datum",
    duration: "Dauer",
    comment: "Kommentar...",
    commentText: "Kommentar",
  },
  image: {
    images: "Bilder",
    selectCover: "Klicken Sie auf das Bild, um es als Titelbild festzulegen",
    background: "Hintergrundbild",
    other: "Weitere Fotos",
    addPhoto: "Foto hinzufügen",
    addImage: "Bild hinzufügen",
    formats: "SVG, PNG, JPEG",
  },
  modification: {
    modificationName: "Änderungsname",
    modification: "Änderung",
    modifications: "Änderungen",
    noModifications: "Es wurden noch keine Änderungen hinzugefügt.",
    noModificationOption: "Es wurden noch keine Optionen hinzugefügt.",
    addModification: "Modifikation hinzufügen",
    editModification: "Modifikation bearbeiten",
    selection: {
      selection: "Auswahl",
      types: "Auswahltyp",
      singleSelection: "Einzel",
      singleSelectionDescription: "nur eine Option kann ausgewählt werden",
      multiSelection: "Mehrfach",
      multiSelectionDescription: "mehrere Optionen können ausgewählt werden",
      option: {
        options: "Optionen",
        onlyOnce: "Nur einmal",
        manyTimes: "Mehrmals",
        default: "Standardauswahl",
        addLanguage: "Sprache hinzufügen",
      },
    },
  },
  user: {
    staff: "Personal",
    listOfEmployees: "{{count}} Mitarbeiter",
    employee: "Mitarbeiter",
    addEmployee: "Mitarbeiter hinzufügen",
    editEmployee: "Mitarbeiter bearbeiten",
    sendAllDetails: "Neues Passwort generieren und an den Benutzer senden",
    userDeleteError:
      "Sie können das Konto, mit dem Sie angemeldet sind, nicht löschen",
  },
  table: {
    connected: "Mit QR verbunden",
    tablesLowercase: "Tische",
    tables: "Tische",
    table: "Tisch",
    addTable: "Tisch hinzufügen",
    editTable: "Tisch bearbeiten",
    selectTable: "Tisch auswählen",
    busy: "Tische besetzt",
    available: "Tische verfügbar",
    full: "voll",
    noTablesVisible: "Keine Tische sind derzeit auf der Karte sichtbar.",
    map: "Karte",
    current: "aktuell",
  },
  zone: {
    zone: "Zone",
    tables: "Tische",
    zones: "Zonen",
    name: "Zonenname",
    allZones: "Alle Zonen",
  },
  map: {
    removeFromMap: "Von der Karte entfernen",
    shapes: {
      rectangle: "Rechteck",
      circle: "Kreis",
    },
    createTable: "Tisch erstellen",
    addToMap: "Zur Karte hinzufügen",
    windowAndDoor: "Fenster & Tür",
    designElements: "Designelemente",
    decoration: "Dekoration",
    reset: "Zurücksetzen",
    width: "Breite",
    height: "Höhe",
  },

  qr: {
    qr: "QR",
    qrCode: "QR-Code",
    name: "QR-Name",
    list: "QR-Liste",
    id: "QR-ID",
    type: "QR-Code-Typ",
    status: "Status",
    unlinked: "Nicht verbunden",
    linked: "Verbunden",
    qrIsNotAdded: "QR ist nicht hinzugefügt",
    connected: "Verbunden mit",
    notConnected: "Nicht verbunden",
    scanToConnect: "oder scannen, um sich zu verbinden",
    QRLinkCopied: "QR link kopiert",
    types: {
      qrType: "QR-Typ",
      wifi: "WLAN",
      menu: "Menü",
    },
  },
  modal: {
    deleteModalTitle: "Sind Sie sicher, dass Sie löschen möchten?",
    deleteModalDescription: "Diese Aktion kann nicht rückgängig gemacht werden",
    warningModalTitleFinishOrder:
      "Sind Sie sicher, dass Sie diese Bestellung abschließen möchten?",
    warningModalTitleChangeZone:
      "Sind Sie sicher, dass Sie diese Zone ändern möchten?",
    warningModalTitleLogout: "Sind Sie sicher, dass Sie sich abmelden möchten?",
    warningModalTitleNewOrder:
      "Sie erstellen eine neue Bestellung in {{zone}}/{{table}}. Sind Sie sicher?",
    warningModalDescriptionNewOrder:
      "Bitte klicken Sie auf die Ja-Taste, um eine neue Bestellung zu erstellen",
    warningModalTitleUnsavedChanges:
      "Sind Sie sicher, dass Sie ohne Speichern verlassen möchten?",
    warningModalDescription: "Ihre Änderungen werden nicht gespeichert",
    yes: "Ja",
    no: "Nein",
    close: "Schließen",
    apply: "Anwenden",
    id: "ID",
  },
  menu: {
    preview: "Vorschau-Menü",
    translate: {
      ai: "Magische Übersetzung",
      translating: "Übersetzung...",
      aiAutoTranslate: "KI Automatische Übersetzung",
      languageSelection: "Sprachauswahl",
      successfulTranslate: "Erfolgreich übersetzt",
      waitingTranslate:
        "Die KI benötigt einige Minuten, um Ihr Menü zu übersetzen. Bitte beachten Sie, dass Menüelemente während dieser Zeit nicht bearbeitet werden können.",
      magicTranslate: "Übersetzen Sie es",
      itemErrorMessage:
        "Dieses Element existiert nicht in der Quellsprache. Um es zu übersetzen, fügen Sie bitte das Element in der Quellsprache hinzu.",
      categoryErrorMessage:
        "Diese Kategorie existiert nicht in der Quellsprache. Um es zu übersetzen, fügen Sie bitte die Kategorie in der Quellsprache hinzu.",
    },
    tag: {
      tag: "Tag",
      tags: "Tags",
      name: "Tag-Name",
    },
    category: {
      category: "Kategorie",
      categories: "Kategorien",
      add: "Kategorie hinzufügen",
      all: "Alle Kategorien",
      name: "Kategoriename",
      mealCategories: "Essenskategorien",
      emptyGuestMenuTitle: "Menü ist leer",
      emptyGuestMenuDescription:
        "Es scheint, dass das Geschäft noch nichts zum Menü hinzugefügt hat",
      emptyAdminCategoryDescription: "Sie haben noch keine Kategorien.",
    },
    item: {
      items: "Artikel",
      item: "Artikel",
      all: "Alle artikel",
      itemLowerCase: "artikel",
      itemsLowerCase: "artikel",
      selected: "Ausgewählte Artikel",
      deleteItem: "Artikel löschen",
      duplicateItem: "Artikel duplizieren",
      duplicate: "Duplizieren",
      scheduleDiscount: "Rabatt planen",
      emptyAdminMenuItemDescription: "Sie haben noch keine Artikel.",
      itemDeleteError:
        "Sie können dieses Produkt nicht löschen, da es in einer aktiven Bestellung vorhanden ist.",
    },
    view: {
      selection: "Standard-Menüansicht",
      scroll: "Scrollen",
      list: "Liste",
      threeD: "3D",
      poker: "Poker",
      grid: "Raster",
    },
    filter: {
      filters: "Filter",
      priceRange: "Preisspanne",
      noResult: "Keine Ergebnisse",
      noResultDesc: "Die aktiven Filter verbergen alle Menüartikel.",
    },
    settings: {
      color: "Farbe",
      radius: "Randradius",
      roundness: "Rundheit",
      currency: "Währung",
      colors: {
        primary: "Primärfarben",
        red: "Rot",
        gold: "Gold",
        purple: "Lila",
        blue: "Blau",
      },
      curves: {
        elementCurves: "Elementkurven",
        none: "Keine",
        small: "Klein",
        medium: "Mittel",
        large: "Groß",
      },
      changeProperties: "Eigenschaften ändern",
      details: "Details des Menüs",
    },
  },
  business: {
    businesses: "Unternehmen",
    list: "Liste der Unternehmen",
    staffList: "{{count}} Mitarbeiter",
    selectTitle: "Unternehmen auswählen",
    selectSubTitle:
      "Bitte wählen Sie das Unternehmen, das Sie betreten möchten, und fahren Sie dann fort",
    contact: "Kontakt",
    contactWith: "Kontakt mit",
    workingHours: "Öffnungszeiten",
    needToKnow: "Wissenswertes",
    addNew: "Unternehmen hinzufügen",
    edit: "Unternehmen bearbeiten",
    plan: "Plan",
    selectPlan: "Plan auswählen",
    creationDate: "Erstellungsdatum",
    status: "Status",
    verified: "Verifiziert",
    unverified: "Nicht verifiziert",
    businessStatus: "Unternehmensstatus",
    expirationDate: "Ablaufdatum",
    country: "Land",
    printers: "Drucker",
    printer: "Drucker",
    guestBusinessBlockTitle: "Dieses Unternehmen ist inaktiv.",
    guestBusinessBlockSubTitle:
      "Es tut uns leid, dieses Unternehmen ist nicht mehr verfügbar.",
    settings: {
      goLive: "Live gehen",
      chat: "Chat",
      realTimeOrder: "Echtzeit",
      menuItemCalorie: "Kalorien",
      menuItemTime: "Zeit",
      menuItemAmount: "Menge",
      reservation: "Reservierung",
      reservationMap: "Karte bei Reservierung anzeigen",
      chatSound: "Chat",
      orderSound: "Bestell",
      askForBillSound: "Rechnungsanforderungs",
      callWaiterSound: "Kellner-Ruf",
      isAskForBillEnabled: "Rechnungsanforderungs",
      isCallWaiterEnabled: "Kellner-Ruf",
      menu: "Menü",
      sound: "Ton",
      general: "Allgemein",
      order: "Bestellung",
      action: "Handlungen",
      reservationVRLink: "Virtuellen link hinzufügen",
      reservationVREnabled: "Virtuelle ansicht",
      reservationMapEnabled: "Kartenansicht",
    },
  },
  orderItem: {
    denied: "Abgelehnt",
    deleted: "Gelöscht",
    empty: "Leer",
  },
  basket: {
    basket: "Warenkorb",
    favorites: "Favoriten",
    basketAndFavorites: "Warenkorb & Favoriten",
    addMessage: "Nachricht für das Restaurant hinzufügen",
    messagePlaceholder:
      "Sonderwünsche, Allergien, diätetische Einschränkungen?",
    itemAdded: "{{count}} Artikel zum Warenkorb hinzugefügt",
    order: {
      info: "Bestellinformationen",
      subtotal: "Zwischensumme",
      total: "Gesamt",
      tax: "Steuer",
      promoCode: "Promo-Code",
      totalCost: "Gesamtkosten",
      orders: "Bestellungen",
      myOrders: "Meine Bestellungen",
      ordersLowerCase: "bestellungen",
      orderLowerCase: "bestellung",
      order: "Bestellung",
      bill: "Rechnung",
      noOrder: "Es gibt keine verfügbare Bestellung",
      deletedOrderTitle: "Ihre Bestellung wurde gelöscht",
      deletedOrderDescription:
        "Es tut uns leid, Ihre Bestellung wurde von unserem Admin/Kellner gelöscht",
      notifications: "Benachrichtigungen",
      updatesToSave: "Updates zum Speichern",
      newOrder: "Neue Bestellung",
      deleteOrder: "Bestellung löschen",
      finishOrder: "Bestellung abschließen",
      done: "Fertig",
      preparing: "Vorbereitung",
      numberOfGuests: "Anzahl der Gäste",
      fullNessOfTable: "Auslastung des Tisches",
      acceptAll: "Alle akzeptieren",
    },
    emptyBasketTitle: "Ihr Warenkorb ist leer",
    emptyBasketDescription:
      "Es sieht so aus, als hätten Sie noch nichts in Ihren Warenkorb hinzugefügt",
  },
  orders: {
    orders: "Bestellungen",
    order: "Bestellung",
    guests: "Gäste",
    guest: "Gast",
  },
  guests: {
    guest: "Gast",
    guests: "Gäste",
    listOfGuests: "{{count}} Gäste",
    allBusinesses: "Alle Unternehmen",
  },

  funZone: {
    wheelOfFortune: {
      spinnerGame: "Spinner Spiel",
      who: "Wer?",
      whoWillPayTheBill: "Wer wird die rechnung bezahlen?",
      whoWillGoToTheMarket: "Wer geht auf den markt?",
      whoWillBuyCigarettes: "Wer wird zigaretten kaufen?",
      whoWillHaveAnotherShot: "Wer nimmt noch einen drink?",
      whoWillGetDessert: "Wer bekommt das dessert?",
      whoWillChooseTheMusic: "Wer wählt die musik aus?",
      whoWillSingKaraoke: "Wer wird karaoke singen?",
      start: "Start",
      spinning: "Dreht...",
      editToPlay: "Zum spielen bearbeiten",
      addNew: "Neu hinzufügen",
      saveAndContinue: "Speichern und fortfahren",
      typeHere: "Hier eingeben",
      result: "Ergebnis",
      youAreTheChosenOne: "Du bist der auserwählte",
    },
  },

  dashboard: {
    dashboard: "Dashboard",
    sales: "Verkäufe",
    salesAnalytics: "Gesamtumsatz / Stunde",
    weekStatistics: "Wochenstatistik",
    statistics: "Statistiken",
    totalSales: "Gesamtumsatz",
    paymentMethodAnalysis: "Zahlungsmethodenanalyse",
    byNumberOfOrders: "Nach Anzahl der Bestellungen",
    byTheAmountOfSales: "Nach Umsatzhöhe",
    byTheNumberOfRatings: "Nach Anzahl der Bewertungen",
    bestselling: "Bestseller",
    worstSelling: "Am schlechtesten verkauft",
    processing: "In Bearbeitung",
    billReady: "Rechnung wird vorbereitet",
    unSelect: "Alle Abwählen",
    denied: "Abgelehnt",
    deleted: "Gelöscht",
    showMore: "Mehr anzeigen",
    showLess: "...Weniger anzeigen",
    bestSellingCategory: "Bestverkaufte Kategorie",
    topPerformer: "Top-Leistungsträger",
    lowPerformer: "Schlechtleistungsträger",
    noResultForThisPeriodMsg: "Für diesen Zeitraum liegen keine Ergebnisse vor",
    all: "Alle",
    yourTable: "Ihr Tisch",
    totalGuests: "Gesamtanzahl Gäste",
    totalItems: "Gesamtanzahl Artikel",
    weeklySaleStatistics: "Wöchentliche Verkaufsstatistik",
    weeklyOrderStatistics: "Wöchentliche Bestellstatistik",
    legends: {
      sale: "Verkauf",
      revenue: "Einnahmen",
      orders: "Bestellanzahl",
      orderItems: "Artikelanzahl",
      totalSale: "Gesamtverkauf",
    },
    guest: {
      guests: "Gäste",
      guest: "Gast",
      me: "Ich",
      review: {
        meal: "Mahlzeit",
        service: "Service",
        review: "Bewertung",
        sent: "Gesendet",
        feedBack: "Rückmeldung",
      },
    },
    staffStatistics: {
      sales: "Verkäufe",
      orders: "Bestellungen",
      rating: "Bewertung",
    },
    orderStatistics: {
      totalSale: "Gesamtverkauf",
      revenue: "Einnahmen",
      averageBill: "Durchschnittliche Rechnung",
      orderCount: "Bestellanzahl",
      guestsServed: "Bediente Gäste",
      averageTime: "Durchschnittliche Zeit",
      from: "von gestern",
      orders: "Bestellungen",
      sale: "Verkauf",
      in: "In",
      out: "Aus",
    },
    table: {
      adminOrderHistoryTable: {
        staff: "Mitarbeiter",
        dates: "Daten",
        item: "Artikel",
        subTotal: "Zwischensumme",
        discount: "Rabatt",
        total: "Gesamt",
        zone: "Zone",
        table: "Tisch",
        service: "Service",
        fee: "Gebühr",
        orderDetails: "Bestelldetails",
        receipt: "Quittung",
      },
      menuStatisticsTable: {
        menuItem: "Menüpunkt",
        category: "Kategorie",
        cost: "Kosten",
        revenue: "Einnahmen",
        total: "Gesamt",
        order: "Bestellung",
        count: "Anzahl",
        price: "Preis",
        item: "Artikel",
      },
    },
    pagination: {
      prev: "Zurück",
      next: "Weiter",
    },
    prevNext: {
      previous: "Vorherige",
      next: "Nächste",
    },
    placeholder: {
      zone: "Zone",
      orderId: "Bestell-ID",
      staff: "Mitarbeiter",
      itemName: "Artikelname",
      categories: "Kategorien",
    },
  },
  login: {
    or: "oder",
    facebook: "Mit Facebook",
    google: "Mit Google",
    pin: "PIN",
    signIn: {
      title: "Anmelden",
      subTitle: "Kein Konto?",
      details: "Anmeldedetails",
      method: "Anmeldemethode",
    },
    signUp: {
      title: "Registrieren",
      subTitleForUserExist:
        "Sie haben bereits ein Konto. Bitte geben Sie Ihr bestehendes Passwort ein.",
      subTitleForUserNotExist:
        "Bitte geben Sie das Passwort ein, das an: {{emailOrPhone}} gesendet wurde.",
      alreadyHaveAccount: "Schon ein Konto?",
      passwordSentTo: "Passwort wurde gesendet an",
      setPinSubTitle:
        "Legen Sie einen PIN-Code für die einfachste Anmeldung fest",
      agree: "Durch die Fortsetzung stimmen Sie den",
      termsAndPrivacy: "AGB & Datenschutzrichtlinien zu",
      enterPassword: "Geben Sie Ihr Passwort ein",
      setPinSubtitle:
        "Legen Sie einen PIN-Code für die einfachste Anmeldung fest",
    },
    forgotPassword: {
      title: "Passwort vergessen?",
      subTitle:
        "Geben Sie Ihre Telefonnummer ein und wir senden Ihnen das Passwort erneut.",
      sendPassword: "Passwort senden",
    },
    resetPassword: {
      title: "Passwort ändern",
      subTitle: "Willkommen zurück, wir haben Sie vermisst!",
    },
    resendPassword: {
      title: "Passwort erneut senden",
      subTitleForUserExist: "Haben Sie Ihr bestehendes Passwort vergessen?",
      subTitleForUserNotExist: "Haben Sie das Passwort nicht erhalten?",
      buttonTextForUserExist: "Neues Passwort senden",
      buttonTextForUserNotExist: "Erneut senden",
    },
  },
  payment: {
    payment: "Zahlung",
    method: "Zahlungsmethode",
    pageTitle: "Wie möchten Sie bezahlen?",
    pageSubtitle:
      "Wählen Sie eine Methode, mit der Sie Ihre Zahlung vornehmen möchten",
    cash: "Bar",
    POSTerminal: "POS-Terminal",
    ready: "In einer Minute fertig!",
    confirmPOSTerminal:
      "Bestätigte Anfrage. Das POS-Terminal wird bald vom Kellner gebracht",
    confirmCash:
      "Bestätigte Anfrage. Ihre Rechnung wird bald vom Kellner gebracht",
    totalCostFor: "Gesamtkosten für",
    printsAndBill: "Drucke und Rechnung",
    paymentConfirmedTitle:
      "Ihre Bestellung ist abgeschlossen. Vielen Dank, dass Sie uns gewählt haben.",
    paymentConfirmedDescription:
      "Es war uns eine Freude, Sie zu bewirten! Bis zum nächsten Mal 👋 Schönen Tag noch 😍",
  },
  waiter: {
    waiter: "Kellner",
    waiterLowercase: "kellner",
    call: "Kellner rufen",
    bill: "Rechnung anfordern",
    quickActions: "Schnellaktionen",
    actions: "Aktionen",
  },
  mascot: {
    dashboard: {
      support: "Unterstützung und Abkürzungen",
      eatIn: "Vor Ort essen",
      eatInHall: "Speisesaal",
      reserveTable: "Tisch reservieren",
      letUs: "Lassen Sie uns .. für Sie",
      learnMore: "Mehr erfahren ",
      fixProblemText: "Haben Sie Probleme mit der App?",
      fixProblemButton: "Klicken Sie hier, um zu beheben",
    },
  },
  errorMessages: {
    selectTableToCreateOrder:
      "Wählen Sie einen Tisch, um eine Bestellung zu erstellen",
    selectWaiterToCreateOrder:
      "Wählen Sie einen Tisch, um eine Bestellung zu erstellen",
    lastLanguage: "Die letzte Sprache kann nicht entfernt werden",
    orderAssignee: "Sie müssen einen Beauftragten auswählen",
    required: {
      password: "Passwort ist erforderlich",
      confirmPassword: "Passwort bestätigen ist erforderlich",
      customMessage: "{{name}} ist erforderlich",
    },
    image: "Bild-Upload fehlgeschlagen",
    addUnit: "Einheit hinzufügen",
    confirmOrders:
      "Sie haben unvollständige Arbeiten. Bitte speichern Sie diese, bevor Sie fortfahren.",
    input: {
      maxCharacter: "*Bitte geben Sie nicht mehr als {{max}} Zeichen ein",
      minCharacter: "*Bitte geben Sie mindestens {{min}} Zeichen ein",
      maxNumber: "*Bitte geben Sie eine Zahl kleiner als {{max}} ein",
      minNumber: "*Bitte geben Sie eine Zahl größer als 0 ein",
      invalid: "*Ungültig {{name}}",
      required: "*{{name}} ist erforderlich",
      generalRequired: "*Dieses Feld ist erforderlich",
      qrAndTable: {
        maxSeat: "Die Anzahl der Sitzplätze muss eine ganze Zahl sein",
      },
      menu: {
        fixedDiscount: "*Rabatt darf den Verkaufspreis nicht übersteigen",
        unFixedDiscount: "*Rabatt darf 100% nicht übersteigen",
        requiredUnit:
          "*Bitte wählen Sie eine Einheit für den angegebenen Betrag",
      },
      menuSettings: {
        requiredDefaultLanguage: "*Bitte wählen Sie eine Standardsprache",
        requiredAvailableLanguages:
          "*Bitte wählen Sie mindestens eine Sprache aus",
      },
      pinCode: {
        maxDigits: "*Der PIN-Code sollte weniger als 6 Ziffern haben",
        alreadyExists: "*Dieser PIN-Code existiert bereits",
        notNumeric: "*Der PIN-Code sollte nur Ziffern enthalten",
        skipOrSetNow:
          "*Jetzt einen PIN-Code festlegen oder diesen Schritt überspringen",
        notMatch: "*PIN-Codes stimmen nicht überein",
        incorrect: "*Der PIN-Code ist falsch",
      },
      email: {
        alreadyExists: "*Diese E-Mail existiert bereits",
        incorrect: "*Bitte geben Sie eine gültige E-Mail-Adresse ein",
        notExists: "*Diese E-Mail existiert nicht",
      },
      password: {
        incorrect: "*Falsches Passwort",
        notNumeric: "*Das Passwort sollte nur Ziffern enthalten",
        incorrectLength: "*Das Passwort sollte 6 Ziffern haben",
      },
      reservation: {
        maxGuestCount: "Die Anzahl der Gäste muss eine ganze Zahl sein",
      },

      phoneNumber: {
        incorrectLength: "*Die Telefonnummer sollte 12 Ziffern haben",
        notNumeric: "*Die Telefonnummer sollte nur Ziffern enthalten",
        alreadyExists: "*Diese Telefonnummer existiert bereits",
        incorrect: "*Bitte geben Sie eine gültige Telefonnummer ein",
        notExists: "*Diese Telefonnummer existiert nicht",
      },
      business: {
        serviceFeeMax: "*Die Servicegebühr sollte weniger als 100% betragen",
        serviceFeeMin: "*Die Servicegebühr sollte mehr als 0% betragen",
        url: "*Bitte geben Sie eine gültige URL ein",
      },
      contact: {
        instagram: "*Bitte geben Sie eine gültige Instagram-Adresse ein",
      },
    },
  },
  toastMessages: {
    success: {
      login: "Anmeldung erfolgreich!",
      updateBusinessProfile: "Geschäftsprofil erfolgreich aktualisiert",
      updateTag: "Tag erfolgreich aktualisiert",
      createTag: "Tag erfolgreich erstellt",
      deleteTag: "Tag erfolgreich gelöscht",
      createReservation: "Reservierung erfolgreich erstellt",
      deleteReservation: "Reservierung erfolgreich gelöscht",
      updateReservation: "Reservierung erfolgreich aktualisiert",
      updateCategory: "Kategorie erfolgreich aktualisiert",
      createCategory: "Kategorie erfolgreich erstellt",
      deleteCategory: "Kategorie erfolgreich gelöscht",
      updateMenuItem: "Menüpunkt erfolgreich aktualisiert",
      updateMenu: "Menü erfolgreich aktualisiert",
      createMenuItem: "Menüpunkt erfolgreich erstellt",
      deleteMenuItem: "Menüpunkt erfolgreich gelöscht",
      duplicateMenuItem: "Menüpunkt erfolgreich dupliziert",
      updateZone: "Zone erfolgreich aktualisiert",
      createZone: "Zone erfolgreich erstellt",
      deleteZone: "Zone erfolgreich gelöscht",
      updateTable: "Tisch erfolgreich aktualisiert",
      updateMenuSettings: "Menüeinstellungen erfolgreich aktualisiert",
      createTable: "Tisch erfolgreich erstellt",
      deleteTable: "Tisch erfolgreich gelöscht",
      updateUser: "Benutzer erfolgreich aktualisiert",
      createUser: "Benutzer erfolgreich erstellt",
      deleteUser: "Benutzer erfolgreich gelöscht",
      updateQR: "QR-Code erfolgreich aktualisiert",
      createQR: "QR-Code erfolgreich erstellt",
      deleteQR: "QR-Code erfolgreich gelöscht",
      updateOrder: "Bestellung erfolgreich aktualisiert",
      createOrder: "Bestellung erfolgreich erstellt",
      deleteOrder: "Bestellung erfolgreich gelöscht",
      finishedOrder: "Bestellung erfolgreich abgeschlossen",
      updateInventoryCategory: "Inventarkategorie erfolgreich aktualisiert",
      createInventoryCategory: "Inventarkategorie erfolgreich erstellt",
      deleteInventoryCategory: "Inventarkategorie erfolgreich gelöscht",
      updateInventoryItem: "Inventargegenstand erfolgreich aktualisiert",
      createInventoryItem: "Inventargegenstand erfolgreich erstellt",
      deleteInventoryItem: "Inventargegenstand erfolgreich gelöscht",
      updateBusiness: "Geschäft erfolgreich aktualisiert",
      createBusiness: "Geschäft erfolgreich erstellt",
      deleteBusiness: "Geschäft erfolgreich gelöscht",
      updateLanguage: "Sprache erfolgreich geändert",
    },
    error: {
      common: "Etwas ist schiefgegangen! Bitte versuchen Sie es erneut",
      zones: "Sie können diese Zone nicht löschen, da sie einen Tisch enthält",
      unselectedTable: "Sie müssen einen Tisch auswählen",
      unselectedZone: "Sie müssen eine Zone auswählen",
    },
  },
  reservation: {
    reserve: "Reservieren",
    reservationTime: "Reservierungszeit",
    reservationInfo: "Reservierungsinformationen",
    contactInfo: "Kontaktinformationen",
    enterName: "Name eingeben",
    enterPhoneNumber: "Telefonnummer",
    bookingList: "Buchungsliste",
    booked: "Gebucht",
    pending: "Ausstehend",
    createReservation: "Reservierung erstellen",
    editReservation: "Reservierung bearbeiten",
    waiting: "Wartend",
    today: "Heute",
    guestNumber: "Anzahl der Gäste",
    waitingTime: "Anfragezeit",
    startDate: "Startdatum",
    ReservationDuration: "Reservierungsdauer",
    comment: "Kommentar",
    numberOfGuest: "Anzahl der Gäste",
    startTime: "Startzeit",
    duration: "Dauer",
    confirmed: "Bestätigt",
    denied: "Abgelehnt",
    delete: "Löschen",
    update: "Aktualisieren",
    searchRestaurant: "Restaurant suchen",
    tableNotFound: "Nicht ausgewählt",
    showMore: "Mehr anzeigen",
    showLess: "Weniger anzeigen",
    reservations: "Reservierungen",
    open: "Offen",
    seeMenu: "Speisekarte ansehen",
    reservation: "Reservierung",
    contact: "Kontakt",
    place: "Ort",
    reserveeName: "Name des Reservierenden",
    date: "Datum",
    time: "Zeit",
    reservationStatus: "Reservierungsstatus",
    reservationDurationHelpText:
      "Hinweis: Wenn Sie die Dauer nicht wählen, wird sie automatisch auf 1 Stunde gesetzt.",
    justNow: "Gerade jetzt",
    oneDay: "1 Tag",
    day: "Tag",
    hour: "Std",
    minute: "Min",
    selectedTable: "Ausgewählter Tisch",
    confirmation: "Bestätigen und fortfahren",
    chooseRestaurant: "Restaurant auswählen",
    setDate: "Wann besuchen Sie uns?",
    chooseTable: "Haben Sie einen bevorzugten Tisch?",
    addContact: "Wer besucht uns?",
    statuses: {
      PENDING: "Ausstehend",
      CONFIRMED: "Bestätigt",
      DENIED: "Abgelehnt",
      DELETED: "Gelöscht",
    },
    vr: {
      browserDoesNotSupport: "Ihr Browser unterstützt das Video-Tag nicht.",
      vrTourReservation: "VR-Tour-Reservierung",
      explore: "Erkunden und Ihren Platz wählen!",
      vrView: "VR-Ansicht",
    },
    map: {
      chooseTable: "Tisch wählen",
      mapReservation: "Reservierung auf der Karte",
      chooseLocation: "Wählen Sie den Standort Ihres Tisches!",
      tables: "Tische",
    },
    sentSuccessfully: "Anfrage erfolgreich gesendet",
    successMessage:
      "Ihre Anfrage wurde gesendet. Updates werden an Ihre Nummer gesendet, und Sie können Ihren Status auf der Reservierungsseite überprüfen",
  },
  socials: {
    instagram: "Instagram",
    email: "E-Mail-Adresse",
    whatsapp: "Whatsapp",
  },
  months: {
    january: "Januar",
    february: "Februar",
    march: "März",
    april: "April",
    may: "Mai",
    june: "Juni",
    july: "Juli",
    august: "August",
    september: "September",
    october: "Oktober",
    november: "November",
    december: "Dezember",
  },
  weekdays: {
    monday: "Montag",
    tuesday: "Dienstag",
    wednesday: "Mittwoch",
    thursday: "Donnerstag",
    friday: "Freitag",
    saturday: "Samstag",
    sunday: "Sonntag",
  },
  periods: {
    hourly: "Stündlich",
    daily: "Täglich",
    weekly: "Wöchentlich",
    monthly: "Monatlich",
  },
  chat: {
    typeHere: "Hier tippen...",
    chat: "Chat",
    allMessages: "Alle Nachrichten",
    unreadMessages: "Ungelesene Nachrichten",
    noMessages: "Keine Nachrichten.",
  },
  kitchen: {
    stageNew: "Neu",
    stageProgress: "Vorbereitung",
    stageDone: "Erledigt",
  },
  inventory: {
    productList: "Produktliste",
    product: "Produkt",
    stock: "Bestand",
    warningAmountInfo:
      "Wählen Sie die Mindestmenge aus, um eine Benachrichtigung zu erhalten, wenn sie fast aufgebraucht ist",
  },
  emptyStates: {
    noPrinters: "Es wurden noch keine Drucker hinzugefügt",
    noOption: "Es gibt keine Option",
    noOrder: "Es gibt noch keine Bestellung",
    noItem: "Es gibt noch keinen Artikel",
    noLanguage: "Es gibt keine Sprachen zum Hinzufügen",
    noTableData: "Auf dieser Seite sind keine Daten vorhanden",
    noOrderedCategory:
      "In dieser Kategorie wurden keine bestellten Artikel gefunden",
    noChat: "Sie haben keine Chats aufgrund fehlender Bestellungen",
    noUnread: "Keine ungelesenen Nachrichten.",
    noOrders: "Keine Bestellungen",
    noGuests: "Keine Gäste",
    noSearchResults: "Keine Suchergebnisse",
    noPendingReservations: "Keine ausstehenden Reservierungen.",
    noReservationDescription: "Sie haben noch keine Reservierungen",
    noReservations: "Keine Reservierungen",
    notOpenForReservations:
      "Dieses Restaurant ist nicht für Reservierungen geöffnet.",
    noBusiness: "Kein Geschäft",
    noBusinessDescription:
      "Leider sind alle Restaurantreservierungen geschlossen.",
  },
  info: {
    itemAmount:
      "Die Zahl bezieht sich auf die Menge des Artikels, angegeben in Einheiten wie Kilogramm (kg), Gramm (g), Liter (l) oder Milliliter (ml).",
    itemModificationOptionMaxNumberInfo:
      "Die maximale Anzahl von Änderungsoptionen bezieht sich auf die maximale Anzahl dieser Optionen, die angewendet werden können.",
    general: "Allgemeine Informationen",
    detail: "Detailinformationen",
  },
  stepper: {
    userDetail: "Benutzerdaten",
    passwordVerification: "Passwortüberprüfung",
    setPinCode: "PIN-Code",
  },
  general: {
    companyName: "iLoyal",
    termsAndConditions: "Allgemeine Geschäftsbedingungen",
    privacyPolicy: "Datenschutzerklärung",
    allRightReserved: "Alle Rechte vorbehalten.",
    copyRight: "© 2024 {{value}}.",
    joinUs: "Schließen Sie sich uns an {{emoji}}",
    madeWith: "Hergestellt mit {{emoji}} von",
    madeBy: "Erstellt von <strong>iLoyal</strong>",
    scanQR: "QR-Code scannen für neue Bestellung",
    name: "Zeig deinen Freunden, dass du es bist!",
    profilePicture: " Ihr Profilbild",
  },
  optionTypes: {
    None: "Keine",
  },
  businesses: {
    allBusinesses: "Alle Unternehmen",
    specialOffers: "Sonderangebote",
    mostPopular: "Am Beliebtesten",
    coffeeShop: "Café",
    nationalCuisine: "Nationale Küche",
    nationalCuisineExtended: "Nationale Kücheeeeeeee",
    seaside: "Am Meer",
    information: "Informationen",
    search: "Suchen...",
    seeAll: "Alle anzeigen",
    seeLess: "Weniger anzeigen",
  },
  policy: {
    TermsOfService: "Nutzungsbedingungen (Lizenzvereinbarung)",
    termsDataInfo:
      "Durch die Fortsetzung stimmen Sie den <strong>Bedingungen und der Datenschutzrichtlinie zu.</strong>",

    joinUsTermsData: {
      introduction: {
        title: "1. Einführung",
        content: {
          point1:
            "Durch die Nutzung unserer Dienste stimmen Sie diesen Nutzungsbedingungen und unserer Datenschutzrichtlinie zu. Bitte lesen Sie diese sorgfältig durch.",
        },
      },
      userConsent: {
        title: "2. Zustimmung des Nutzers",
        content: {
          point1:
            "Durch den Zugriff auf oder die Nutzung unserer Dienste stimmen Sie der Sammlung, Nutzung und Weitergabe Ihrer Informationen gemäß diesen Nutzungsbedingungen und der Datenschutzrichtlinie zu. Wenn Sie mit diesen Bedingungen nicht einverstanden sind, verwenden Sie bitte unsere Dienste nicht.",
        },
      },
      dataCollection: {
        title: "3. Datensammlung",
        content: {
          point1:
            "Wir sammeln Informationen, die Sie uns direkt bereitstellen, z. B. wenn Sie ein Konto erstellen, einen Kauf tätigen oder uns um Unterstützung bitten. Wir können auch automatisch Informationen sammeln, wie Ihre IP-Adresse, Ihren Browsertyp und Nutzungsdaten.",
        },
      },
      dataUse: {
        title: "4. Datennutzung",
        content: {
          point1:
            "Ihre Daten werden verwendet, um unsere Dienste bereitzustellen, aufrechtzuerhalten und zu verbessern. Dazu gehört die Verarbeitung von Transaktionen, das Versenden von Kommunikationen und die Anpassung Ihres Erlebnisses.",
        },
      },
      dataProtection: {
        title: "5. Datenschutz",
        content: {
          point1:
            "Wir sind verpflichtet, Ihre Daten zu schützen. Wir setzen verschiedene Sicherheitsmaßnahmen um, um Ihre Informationen vor unbefugtem Zugriff, Änderung, Offenlegung oder Zerstörung zu schützen.",
        },
      },
      dataSharing: {
        title: "6. Datenweitergabe",
        content: {
          point1:
            "Wir verkaufen Ihre persönlichen Daten nicht. Wir können Informationen mit vertrauenswürdigen Dritten teilen, die uns bei der Verwaltung unserer Website, der Durchführung unserer Geschäfte oder der Bereitstellung von Dienstleistungen für Sie unterstützen, vorausgesetzt, diese Parteien stimmen zu, diese Informationen vertraulich zu behandeln.",
        },
      },
      userRights: {
        title: "7. Nutzerrechte",
        content: {
          point1:
            "Sie haben das Recht, auf Ihre persönlichen Informationen zuzugreifen, sie zu korrigieren oder zu löschen. Sie können auch bestimmte Verwendungen und Offenlegungen Ihrer Daten ablehnen.",
        },
      },
      general: {
        title: "",
        content: {
          point1:
            "Durch die Nutzung unserer Dienste erkennen Sie an, dass Sie diese Bedingungen gelesen und verstanden haben und sich verpflichten, an diese gebunden zu sein.",
        },
      },
    },

    signUpTermsData: {
      agreement: {
        title: "1. Bedingungen der Vereinbarung",
        content: {
          point1:
            "1.1 Der Lizenzgeber muss die Softwarelizenz gemäß den Bedingungen dieser Vereinbarung bereitstellen, und der Kunde muss für diese Lizenz bezahlen.",
          point2:
            "1.2 Die monatliche Lizenzgebühr (Abonnement) wird basierend auf der Vielzahl der von iLoyal bereitgestellten Funktionen entsprechend den Bedürfnissen des Kunden bestimmt. Änderungen der Lizenzgebühr (Abonnement) können nur auf Grundlage einer gegenseitigen Vereinbarung zwischen dem Kunden und iLoyal vorgenommen werden. Die monatliche Lizenzperiode (Abonnement) beginnt mit dem Datum der Unterzeichnung der Vereinbarung.",
        },
      },
      license: {
        title: "2. Lizenzbedingungen",
        content: {
          point1:
            "2.1 Der Lizenzgeber gewährt unter den Bedingungen einer einfachen Lizenz folgende Rechte:",
          point2:
            "2.1.1 Die Nutzung der Software sicherzustellen und deren Betrieb für den vorgesehenen Zweck zu bestimmen.",
          point3: "2.2 Folgendes ist für die Lizenz VERBOTEN:",
          point4: "2.2.1 Die Extraktion des Software-Codes durch den Benutzer.",
          point5:
            "2.2.2 Die Vervielfältigung durch den Benutzer, d. h. das Erstellen von ein oder mehreren Kopien der Software.",
          point6: "2.2.3 Der Weiterverkauf der Software und ihrer Kopien.",
          point7: "2.2.4 Das öffentliche Zugänglichmachen der Software.",
        },
      },
      payment: {
        title: "3. Lizenzzahlung",
        content: {
          point1:
            "3.1 Der Kunde erklärt sich bereit, den in der Rechnung für die in dieser Vereinbarung aufgeführten Lizenzen angegebenen Betrag zu zahlen.",
          point2:
            "3.2 Die Preise der Lizenzen sind auf der offiziellen Website des Lizenzgebers aufgeführt.",
          point3:
            "3.3 Im Falle der Beendigung der Vereinbarung zwischen den Parteien ist die Lizenzgebühr nicht verhandelbar und nicht erstattungsfähig.",
          point4:
            "3.4 Nach Zahlung der Lizenzgebühr gemäß der vom Lizenzgeber ausgestellten Rechnung gelten Sie als mit den Bedingungen der aktuellen Lizenzvereinbarung einverstanden, und diese Vereinbarung wird zwischen Ihnen und dem Lizenzgeber als abgeschlossen betrachtet.",
          point5:
            "3.5 Der Lizenzgeber behält sich das Recht vor, diese Vereinbarung einseitig zu ändern.",
          point6:
            "3.6 Sobald die entsprechenden Beträge auf dem Bankkonto des Lizenzgebers eingegangen sind, gelten alle Zahlungspflichten des Kunden als erfüllt.",
        },
      },
      dataProtection: {
        title: "4. Datenschutz",
        content: {
          point1: "4.1 Alle Kundendaten werden vom Lizenzgeber geschützt.",
          point2:
            "4.2 Kundendaten, die in unseren Cloud-Konten gespeichert sind, werden nicht an Dritte weitergegeben.",
        },
      },
    },
  },
};
export default TRANSLATION;
